<template>
  <div class="wholesale-calendar-container">
    <div v-if="isLoading">
      <infinite-loading
        @infinite="getRedemptions"
        force-use-infinite-wrapper="false"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
    <div v-else>
      <div
        class="d-flex justify-content-center align-items-center mx-4 mb-3 row"
      >
        <div class="col-2">
          <img
            v-if="checkPrev"
            class="arrow-left-icon"
            src="@/assets/icon/arrow-right.svg"
            height="26px"
            width="26px"
            @click="prev"
          />
        </div>
        <div class="col-8 text-center month-section">
          <b>เดือน{{ generateMonth }}</b>
        </div>
        <div class="col-2">
          <img
            v-if="checkNext"
            src="@/assets/icon/arrow-right.svg"
            height="26px"
            width="26px"
            @click="next"
          />
        </div>
      </div>
      <div
        v-for="(i, index) in generateList"
        :key="index"
        class="sale-items mb-3"
      >
        <div
          v-if="
            i.month === selected.month &&
            i.year === selected.year &&
            i.data.length > 0
          "
        >
          <div
            class="sale-card"
            v-for="(j, subIndex) in i.data"
            :key="subIndex"
          >
            <div v-if="subIndex !== 5" class="d-flex">
              <div>{{ j.title }}</div>
              <div class="ml-auto">
                {{ $numberWithCommas(j.amount) }}
              </div>
              <div class="unit-section ml-1">
                {{ j.unit }}
              </div>
            </div>
            <div v-else class="last-index-card d-flex">
              <div>{{ j.title }}</div>
              <div class="ml-auto">
                {{ $numberWithCommas(j.amount) }}
              </div>
              <div class="unit-section ml-1">
                {{ j.unit }}
              </div>
            </div>
          </div>
          <!-- <div v-for="(j, subIndex) in i.data" :key="subIndex">
            <b>{{ j.title }}</b>
            <div class="sale-card p-3">
              <div
                v-for="(k, subIndex2) in j.list"
                :key="subIndex2"
                class="d-flex"
              >
                <div>{{ k.title }}</div>
                <div class="ml-auto">
                  {{ $numberWithCommas(k.amount) }} {{ unit }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div
          v-if="
            i.month === selected.month &&
            i.year === selected.year &&
            i.data.length === 0
          "
        >
          <div class="blank-content">ไม่พบข้อมูล</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    InfiniteLoading,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    dataList: {
      type: Array,
    },
    // unit: {
    //   type: String,
    //   default: "P",
    // },
  },
  data: () => ({
    month: [
      { text: "มกราคม", value: 1 },
      { text: "กุมภาพันธ์", value: 2 },
      { text: "มีนาคม", value: 3 },
      { text: "เมษายน", value: 4 },
      { text: "พฤษภาคม", value: 5 },
      { text: "มิถุนายน", value: 6 },
      { text: "กรกฎาคม", value: 7 },
      { text: "สิงหาคม", value: 8 },
      { text: "กันยายน", value: 9 },
      { text: "ตุลาคม", value: 10 },
      { text: "พฤศจิกายน", value: 11 },
      { text: "ธันวาคม", value: 12 },
    ],
    monthList: [],
    selected: {},
  }),
  computed: {
    generateMonth() {
      const { monthList, selected } = this;
      const index = monthList.findIndex((i) => i.value === selected.month && i.year === selected.year);
      return monthList[index]?.text + " " + (monthList[index]?.year + 543);
    },
    checkNext() {
      const { dataList, selected } = this;
      const { month, year } = selected;
      const index = dataList.findIndex(
        (e) => e.month === month && e.year === year
      );
      return dataList[index + 1] === undefined ? false : true;
    },
    checkPrev() {
      const { dataList, selected } = this;
      const { month, year } = selected;
      const index = dataList.findIndex(
        (e) => e.month === month && e.year === year
      );
      return dataList[index - 1] === undefined ? false : true;
    },
    generateList() {
      const { dataList, month } = this;
      const monthList = [];
      this.assignSelected();
      dataList.forEach((i) => {
        const index = month.findIndex((j) => j.value === i.month);
        monthList.push({
          text: month[index].text,
          value: month[index].value,
          year: i.year,
        });
      });
      this.monthList = monthList; // eslint-disable-line
      return dataList;
    },
  },
  methods: {
    assignSelected() {
      const { dataList } = this;
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      const index = dataList.findIndex(
        (e) =>
          e.month === currentMonth &&
          e.year === currentYear &&
          e.data.length > 0
      );
      if (index !== -1) {
        this.selected = {
          month: currentMonth,
          year: currentYear,
        };
      } else {
        const cloneDataList = cloneDeep(dataList);
        const reverseDataList = cloneDataList.reverse();
        const lastIndex = reverseDataList.findIndex((e) => e.data.length > 0);
        this.selected = {
          month: reverseDataList[lastIndex].month,
          year: reverseDataList[lastIndex].year,
        };
      }
    },
    next() {
      const { selected, dataList } = this;
      const { month, year } = selected;
      const currentIndex = dataList.findIndex(
        (i) => i.month === month && i.year === year
      );
      if (currentIndex !== dataList.length - 1) {
        this.selected = {
          month: dataList[currentIndex + 1]?.month,
          year: dataList[currentIndex + 1]?.year,
        };
      }
    },
    prev() {
      const { selected, dataList } = this;
      const { month, year } = selected;
      const currentIndex = dataList.findIndex(
        (i) => i.month === month && i.year === year
      );
      if (currentIndex !== 0) {
        this.selected = {
          month: dataList[currentIndex - 1]?.month,
          year: dataList[currentIndex - 1]?.year,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$blue: #189ad6;
$light-blue: #f0f6ff;
$light-gray: #ced3da;
$white: #fff;

.wholesale-calendar-container {
  // min-height: 330px;
  .arrow-left-icon {
    transform: rotate(180deg);
  }
  .month-section {
    background: $light-gray;
    border-radius: 10px;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sale-items {
    .sale-card {
      font-size: 14px;
      // background: $light-blue;
      // border-radius: 10px;
      line-height: 2.13;
    }
    .last-index-card {
      background: $blue;
      color: $white;
      font-weight: bold;
      margin: 1rem -1rem;
      padding: 15px;
    }
    .unit-section {
      width: 40px;
      text-align: left;
    }
    .blank-content {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      height: 224px;
    }
  }
}
</style>