<template>
  <div id="history_root" :class="'history root'" :key="changeCount">
    <div class="listData px-3 mt-4">
      <SearchInput
        :keyword="keyword"
        :updateKeyword="updateKeyword"
        :placeholder="'ค้นหา'"
      />
      <div>
        <div
          v-for="(it, index) in generateList"
          :key="index"
          :class="'row item'"
          v-on:click="it.status == 'redeemed' ? itemClick(it) : ''"
          data-tag="item"
        >
          <div class="col-5 p-0 rw_image">
            <img width="100%" v-lazy="it.imageUrl" alt />
          </div>
          <div class="col des p-1">
            <div class>
              Ref:
              {{ it.refRedeemId }}
            </div>
            <div class>
              <i class="icon-calendar"></i>
              {{ dateTolocal(it.createdAt) }}
            </div>
            <div class>
              <i class="icon-gift"></i>
              {{ it.title }}
            </div>
            <div class>
              <i class="icon-product-hunt"></i>
              {{ $toLocal(it.spentPoint) }}
            </div>
            <div :class="it.status">
              <span v-if="it.type == 'luckydraw'">
                <i class="icon-ok-circled"></i> คะแนนที่ใช้แลกสิทธิ์
              </span>
              <span v-else-if="it.status == 'redeemed'">
                <i class="icon-truck"></i> อยู่ระหว่างการจัดส่ง
              </span>
              <span v-else-if="it.status == 'received'">
                <i class="icon-ok-circled"></i> จัดส่งของรางวัลแล้ว
              </span>
            </div>
          </div>
        </div>
        <infinite-loading
          v-if="dataStage != 'error'"
          @infinite="getRedemptions"
          force-use-infinite-wrapper="false"
          ref="infiniteLoading"
          spinner="waveDots"
        >
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
      <div v-if="dataStage == 'loading'">
        <vue-content-loading
          v-for="i in 6"
          :key="i + '_'"
          :weight="100"
          :height="130"
        >
          <rect y="25" rx="15" ry="15" width="37%" height="80%" />
          <rect y="30" x="40%" rx="4" ry="4" width="40%" height="15" />
          <rect y="55" x="40%" rx="4" ry="4" width="60%" height="15" />
          <rect y="80" x="40%" rx="4" ry="4" width="30%" height="15" />
          <rect y="105" x="40%" rx="4" ry="4" width="50%" height="15" />
        </vue-content-loading>
      </div>
      <div v-if="dataStage == 'error'" class="text-center pt-4">
        Coming soon!
      </div>
      <div v-if="dataStage == 'nodata'" class="text-center pt-4">
        Coming soon
      </div>
    </div>

    <b-modal id="confirmRecieved_modal" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline"></i>
      </template>
      <div class="d-block">
        <h4 class="text-center">ยืนยันได้รับสินค้าแล้ว</h4>
        <div class="row content" v-if="modal_data">
          <div class="col-5 p-0 rw_image">
            <img width="100%" v-lazy="modal_data.imageUrl" alt />
          </div>
          <div class="col des p-1">
            <div class>
              Ref:
              {{ modal_data.refRedeemId }}
            </div>
            <div class>
              <i class="icon-calendar"></i>
              {{ dateTolocal(modal_data.createdAt) }}
            </div>
            <div class>
              <i class="icon-gift"></i>
              {{ modal_data.title }}
            </div>
            <div class>
              <i class="icon-product-hunt"></i>
              {{ modal_data.spentPoint }}
            </div>
            <div :class="modal_data.status">
              <span v-if="modal_data.status == 'redeemed'">
                <i class="icon-truck"></i> อยู่ระหว่างการจัดส่ง
              </span>
              <span v-if="modal_data.status == 'received'">
                <i class="icon-ok-circled"></i> จัดส่งของรางวัลแล้ว
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center row">
        <div class="col">
          <button
            v-if="!confirmLoading"
            id="okBtn"
            class="btn btn-primary mt-3 w-100"
            :disabled="$readOnly"
            @click="recievedClick(modal_data.id)"
            data-tag="recieved"
          >
            <span>รับสินค้าแล้ว</span>
          </button>

          <button
            v-if="confirmLoading"
            class="btn btn-primary mt-3 w-100"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span>&nbsp;Loading...</span>
          </button>
        </div>
        <div class="col">
          <button
            id="okBtn"
            class="btn btn-light mt-3 w-100"
            @click="$bvModal.hide('confirmRecieved_modal')"
            data-tag="back"
          >
            กลับ
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import VueContentLoading from "vue-content-loading";
import InfiniteLoading from "vue-infinite-loading";

import SearchInput from "@/components/SearchInput";

export default {
  name: "history",
  components: { VueContentLoading, InfiniteLoading, SearchInput },
  data() {
    return {
      dataStage: "loading",
      next_page_url: `${process.env.VUE_APP_API_URL}/redemptions?page=1`,
      data: [],
      modal_data: null,
      confirmLoading: false,
      changeCount: 0,
      keyword: "",
    };
  },
  methods: {
    updateKeyword(e) {
      this.keyword = e.target?.value;
    },
    searchKeyword(list, keyword) {
      let searchList = [];
      list.forEach((i) => {
        const { title } = i;

        if (title.toLowerCase().includes(keyword.toLowerCase())) {
          searchList.push(i);
        }
      });
      return searchList;
    },
    async fetchRedemptions() {
      // get shop info
      return await fetch(this.next_page_url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async getRedemptions($state) {
      try {
        const res = await this.fetchRedemptions();
        console.log("fetchRedemtions status:", res.status);

        let body = [];
        switch (res.status) {
          case 200:
            body = await res.json();

            this.next_page_url = body.nextPageUrl;
            this.data = this.data.concat(body.data);
            if (this.data.length == 0) {
              this.dataStage = "nodata";
              this.$nextTick(() => {
                $state.complete();
              });
              return;
            }
            if (body.currentPage >= body.lastPage) {
              this.dataStage = "end";
              this.$nextTick(() => {
                $state.complete();
              });
            } else {
              this.dataStage = "already";
              this.$nextTick(() => {
                $state.loaded();
              });
            }
            break;
          default: {
            this.dataStage = "error";
            console.log("no case:");
            break;
          }
        }
      } catch (error) {
        console.log(error);
        this.dataStage = "error";
        this.loadingData = false;
      }
    },
    dateTolocal(val) {
      return new Date(val)
        .toLocaleString("en-GB", { timeZone: "UTC" })
        .split(",")[0];
    },
    itemClick(data) {
      this.modal_data = data;
      this.$nextTick(() => {
        this.$bvModal.show("confirmRecieved_modal");
      });
    },
    async fetchRedemptionConfirm(redemptionID) {
      // get shop info
      return await fetch(
        `${process.env.VUE_APP_API_URL}/redemption/${redemptionID}/confirm`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      );
    },
    async recievedClick(redemptionID) {
      this.confirmLoading = true;
      try {
        const res = await this.fetchRedemptionConfirm(redemptionID);
        console.log("fetchRedemptionConfirm status:", res.status);

        let body = [];
        switch (res.status) {
          case 200:
            {
              body = await res.json();
              this.messageComfirm(body);
              window.$pushEvent("redemption", "confirm", body);
            }
            break;
          default:
            throw "no case " + res.status;
        }
      } catch (error) {
        console.log(error);
        this.$Error();
        window.$pushEvent("redemption", "error", error);
      } finally {
        this.confirmLoading = false;
      }
    },
    messageComfirm(status) {
      switch (status) {
        case "CONFIRM_DELIVERY":
          this.Swal.fire("ยืนยันการรับของสำเร็จ", "", "success").then(() => {
            //this.$forceUpdate();

            this.changeCount += 1;
            this.$bvModal.hide("confirmRecieved_modal");
            this.dataStage = "loading";
            this.next_page_url = `${process.env.VUE_APP_API_URL}/redemptions?page=1`;
            this.data = [];
            this.modal_data = null;
            this.confirmLoading = false;
            this.$refs.infiniteLoading.stateChanger.reset();
          });
          break;
        default:
          this.$Error();
          window.$pushEvent("redemption", "error", status);
      }
    },
  },
  computed: {
    generateList() {
      const { keyword, data } = this;
      if (keyword) {
        return this.searchKeyword(data, keyword);
      } else {
        return data;
      }
    },
  },
  watch: {
    dataStage(val) {
      const elm = document.getElementById("history_root");
      if (val == "loading") {
        elm.classList.add("position-fixed");
      } else {
        elm.classList.remove("position-fixed");
      }
    },
  },
  async mounted() {
    window.loading(false);
  },
};
</script>

<style  scoped>
.root {
  font-size: 16px;
}

.item {
  /* padding: 10px 10px; */
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  padding: 10px 10px;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.rw_image {
  border-radius: 10px;
  overflow: hidden;
}

.des {
  font-size: 14px;
  line-height: 18px;
}

i {
  font-size: 16px;
}

.received {
  color: rgb(64, 125, 128);
}

.redeemed {
  color: rgb(124, 173, 67);
}

.spinner-grow {
  vertical-align: middle;
}

@media screen and (max-width: 400px) {
  .root {
    font-size: 12px;
  }
  .des {
    font-size: 12px;
  }
}
</style>