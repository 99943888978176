<template>
  <div class="search-keyword">
    <img src="@/assets/icon/search.svg" />
    <input
      type="text"
      class="form-control"
      name="keyword"
      maxlength="20"
      :placeholder="placeholder"
      :value="keyword"
      @keyup="updateKeyword"
    />
  </div>
</template>

<script>
export default {
  props: {
    keyword: String,
    updateKeyword: Function,
    placeholder: {
      type: String,
      default: "ค้นหาร้านค้า",
    },
  },
};
</script>

<style lang="scss" scoped>
$gray: #828B95;
$light-gray: #eee;

.search-keyword {
  position: relative;
  img {
    opacity: 0.5;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }
  input {
    // background: $light-gray;
    border-radius: 10px;
    color: $gray;
    padding-left: 40px;
  }
  *::placeholder {
    /* modern browser */
    color: $gray !important;
  }
}
</style>