<template>
  <div class="rewards root">
    <Carousel />
    <Category />
    <div class="separate"></div>
    <RewardList />
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/carousel.vue";
import Category from "@/components/category.vue";
import RewardList from "@/components/rewardList.vue";

export default {
  name: "rewards",
  components: { Carousel, Category, RewardList },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    async fetch() {
      // process.env.VUE_APP_API_URL
      return await fetch(process.env.VUE_APP_API_URL + "/rewards", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async init() {
      let body = [];
      try {
        const res = await this.fetch();
        console.log("fetchRewards status:", res.status);
        switch (res.status) {
          case 200:
            body = await res.json();
            console.log("fetchRewards body:", body);
            this.$store.commit("SET_REWARDS", body);
            return;
          default: {
            throw "no case status";
          }
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("SET_REWARDS_STATUS", "error");
      }
    },
  },
  mounted() {
    this.init();
    window.loading(false);
  },
};
</script>

<style  scoped>
.root {
  font-size: 16px;
  margin-top: 4px;
}
.separate {
  border-bottom: 1px solid #f0f0f0;
}
</style>
