<template>
  <div class="category">
    <div class="px-3 py-2" v-if="statusData == 'loading'">
      <vue-content-loading class :weight="'100%'" :height="42">
        <rect rx="15" ry="15" width="60" height="37" />
        <rect x="70" rx="15" ry="15" width="120" height="37" />
        <rect x="200" rx="15" ry="15" width="90" height="37" />
        <rect x="300" rx="15" ry="15" width="100" height="37" />
      </vue-content-loading>
    </div>
    <ul v-if="statusData == 'ok'" class="listCats">
      <li
        v-if="specialReward !== null"
        :id="`cat_${specialReward.id}`"
        v-on:click="catClick(specialReward.id)"
        class="cat"
      >
        {{ specialReward.title }}
      </li>
      <li id="cat_0" v-on:click="catClick(0)" class="cat">ทั้งหมด</li>
      <li
        :id="'cat_' + it.id"
        v-on:click="catClick(it.id)"
        class="cat"
        v-for="(it, index) in categorys"
        :key="index"
      >
        {{ it.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { findIndex, reduce } from "lodash";
import VueContentLoading from "vue-content-loading";

export default {
  name: "carousel",
  components: { VueContentLoading },
  props: {},
  data() {
    return {
      statusData: "",
      // dataCats: [
      //   { id: 1, text: "ทอง" },
      //   { id: 2, text: "โทรศัพท์" },
      //   { id: 3, text: "เครื่องใช้ไฟฟ้า" },
      //   { id: 4, text: "บัตรกำนัล" }
      // ]
      data: [],
    };
  },
  computed: {
    specialReward() {
      const { category } = this.$store?.state?.rewards;
      const index = findIndex(category, (i) => i.title === "รางวัลพิเศษ");
      // console.log(category[index]);
      return category[index] || null;
    },
    categorys() {
      const { category } = this.$store?.state?.rewards;
      const newCategory = reduce(
        category,
        (a, b) => {
          if (b.title !== "รางวัลพิเศษ") a.push(b);
          return a;
        },
        []
      );
      return newCategory;
    },
    rewardsStatus() {
      return this.$store.state.rewardsStatus;
    },
  },
  watch: {
    rewardsStatus() {
      this.checkStatusData();
    },
  },
  methods: {
    checkStatusData() {
      if (this.rewardsStatus == "error") {
        this.statusData = "error";
        return;
      }
      if (this.rewardsStatus == "ok") {
        if (this.categorys.length > 0) {
          this.statusData = "ok";
          this.$nextTick(() => {
            if (this.specialReward) this.catClick(this.specialReward.id);
            else this.catClick(0);
          });
        }
      }
      if (this.rewardsStatus == "") {
        this.statusData = "loading";
      }
    },
    catClick(id) {
      const catsElm = document.getElementsByClassName("cat");

      for (var i = 0; i < catsElm.length; i++) {
        catsElm[i].classList.remove("active");
      }

      document.getElementById("cat_" + id).classList.add("active");

      // console.log("click tag id:", id);
      this.$store.commit("SET_ACTIVE_CAT_ID", { id: id });
    },
  },
  mounted() {
    this.checkStatusData();
  },
};
</script>


<style scoped>
.listCats {
  padding: 10px;
  overflow: auto;
  white-space: nowrap;
}

.listCats li {
  border: 1px solid #ced3da;
  border-radius: 20px;
  margin: 0 5px;
  padding: 0 10px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
}

.listCats .active {
  border-color: transparent;
  color: #ffffff;
  background: #189ad6;
}

ul {
  margin-bottom: 0;
}
</style>
