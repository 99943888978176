<template>
  <div class="rewardList root container">
    <div class="title" v-show="rewardsStatus == 'ok'">รายการของรางวัล</div>
    <div class v-if="rewardsStatus == 'loading'">
      <div v-for="i in 2" :key="i + '_'" class="row py-1 px-1">
        <div class="col px-1">
          <vue-content-loading class :weight="'100%'" :height="300">
            <rect rx="15" ry="15" width="100%" height="300" />
          </vue-content-loading>
        </div>
        <div class="col px-1">
          <vue-content-loading class :weight="'100%'" :height="300">
            <rect rx="15" ry="15" width="100%" height="300" />
          </vue-content-loading>
        </div>
      </div>
    </div>
    <div v-show="rewardsStatus == 'ok'" class="rewardlist row px-1">
      <div
        v-for="it in data"
        :key="it.id"
        @click="gotoReward(it.id)"
        class="col-6"
      >
        <div class="mycard">
          <img
            class="reward_img"
            v-lazy="it.posterUrl"
            @load="$store.commit('SET_REWARDS_STATUS', 'ok')"
            width="100%"
            alt="..."
          />
          <div class="mycard-body">
            <div class="container">
              <div class="row">
                <div class="col-12 title_item">{{ it.title }}</div>
                <div class="col-12">
                  <div class="rd_point_item">
                    {{ $toLocal(it.redeemPoint) }} คะแนน
                  </div>
                  <!-- <div class="rd_point_txt_item">คะแนน</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="rewardsStatus == 'nodata'" class="text-center my-4">
      Coming soon
    </div>
    <div v-if="rewardsStatus == 'error'" class="text-center my-4">
      Coming soon!
    </div>
  </div>
</template>

<script>
import VueContentLoading from "vue-content-loading";

export default {
  name: "rewardList",
  components: { VueContentLoading },
  data() {
    return {
      statusData: "loading",
    };
  },
  computed: {
    data() {
      if (this.activeCatID > 0) {
        return this.rewardsData?.filter((item) => {
          return item.categoryId == this.activeCatID;
        });
      } else {
        let arrayList = this.rewardsData?.filter(rw => rw.category?.title !== "รางวัลพิเศษ");
        let specialArrayList = this.rewardsData?.filter(rw => rw.category?.title == "รางวัลพิเศษ");

        const reward = arrayList?.sort(
          (a, b) => b.redeemPoint - a.redeemPoint
        );
        const specialReward = specialArrayList?.sort(
          (a, b) => b.redeemPoint - a.redeemPoint
        );

        return specialReward?.concat(reward);
      }
    },
    rewardsData() {
      return this.$store.state.rewards.data;
    },
    activeCatID() {
      return this.$store.state.activeCategoryID;
    },
    rewardsStatus() {
      return this.$store.state.rewardsStatus;
    },
  },
  methods: {
    gotoReward(reward_id) {
      this.$router.push({
        path: "/reward/" + reward_id,
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.root {
  padding-bottom: 20px;
}

.title {
  font-size: 1.2em;
}

.rewardlist > div {
  padding: 7px;
}

.mycard img {
  z-index: -10;
  min-height: 100px;
}

.mycard {
  /* border: 1px solid #ced3da; */
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.15);
  &:active::before {
    overflow: hidden;
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
}

.mycard-body {
  padding: 5px;
  font-size: 0.8em;
}

.mycard-body .row > div {
  padding: 2px;
  word-wrap: break-word;
}

.rd_point_txt_item {
  font-size: 0.7em;
  margin-top: -7px;
}
</style>
