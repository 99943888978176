<template>
  <div class="rewards-announcement-container" data-testid="redeem-announcement">
    <div class="title">- ประกาศรายชื่อผู้โชคดี -</div>
    <b-img :src="redeemAnnounce.imageUrl" fluid-grow />
    <div class="rewards-announcement-content container">
      <div class="title">รายชื่อผู้โชคดี {{redeemAnnounce.title}}</div>
      <div class="content" v-for="(item, i) in redeemAnnounce.rewards" :key="`reward-${item.id}`">
        <div v-if="item.winners">
          <br v-if="i !== 0" />
          {{item.title}} :
          <br />
          <div v-for="(name, index) in item.winners" :key="`name-${index}`">
            <b v-if="name.taxCompanyName">{{name.taxCompanyType}} {{name.taxCompanyName}}</b>
            <b v-else>{{name.taxPrefix}} {{name.taxFirstName}} {{name.taxLastName}}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue";

export default {
  name: "redeemsAnnouncement",
  components: {
    "b-img": BImg,
  },
  props: { data: Object },
  data: () => ({
    redeemAnnounce: [],
  }),
  mounted() {
    if (this.data) {
      window.loading(false);
      this.redeemAnnounce = this.data;
    } else {
      this.$router.push({ name: "lucky-draws" });
    }
  },
};
</script>

<style lang="scss" scoped>
$gray: #ced3da;
$light-gray: #f7f8f9;

.rewards-announcement-container {
  background: $gray;
  height: 100vh;
  > .title {
    background: $light-gray;
    font-size: 28px;
    font-weight: bold;
    padding: 15px 0;
    text-align: center;
  }
  .rewards-announcement-content {
    background: $light-gray;
    padding-top: 12px;
    padding-bottom: 20px;
    > .title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    > .content {
      line-height: 1.5;
    }
  }
}
</style>