<template>
  <div class="uploadWrap">
    <img v-show="previewImg" id="previewImg" width="100%" :src="previewImg" />
    <label for="upload-photo" class="uploadArea">
      <!-- <div class="uploadBtn">เลือกไฟล์...</div> -->
      <div v-if="!uploading">เลือกไฟล์...</div>
      <div v-if="uploading">กำลังโหลด...</div>
    </label>
    <input
      type="file"
      name="photo"
      ref="fileInput"
      :capture="getMobileOperatingSystem() == 'iOS' ? false : true"
      accept="image/*"
      @change="fileChange"
      id="upload-photo"
    />
    <div v-if="!noFile" class="remove mt-1" @click="reset()">
      ลบไฟล์ที่เลือก
    </div>
  </div>
</template>
<script>
import Compressor from "compressorjs";

export default {
  props: { imageUrl: String },
  data() {
    return {
      previewImg: this.imageUrl || null,
      uploading: false, // loading
      noFile: true,
    };
  },
  watch: {
    imageUrl(val) {
      this.previewImg = val;
    },
  },
  methods: {
    reset() {
      this.previewImg = null;
      this.uploading = false;
      this.$refs.fileInput.value = null;
      this.noFile = true;
      this.$emit("result", {});
      this.previewImg = this.imageUrl;
    },
    fileChange(e) {
      const file = e.target.files[0];
      this.noFile = !file;

      if (!file) {
        return;
      }

      this.uploading = true;

      if (file.size > 8388608) {
        this.Swal.fire("", "ขนาดไฟล์ไม่ควรเกิน 8MB", "warning");
        this.uploading = false;
        return;
      }

      this.resizeImage(file);
      // this.preview(file);
    },
    preview(file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        this.previewImg = e.target.result;
      };
    },
    resizeImage(file) {
      new Compressor(file, {
        quality: 0.6,
        convertSize: 0,
        maxWidth: 2400,
        maxHeight: 2400,
        success: (result) => {
          // console.log(result);
          this.preview(result);
          this.uploading = false;
          this.$emit("result", { file: result, fileName: file.name });
        },
        error: (err) => {
          console.log(err.message);
          this.uploading = false;
          this.Swal.fire(
            "",
            "กรุณาตรวจสอบไฟล์ที่เลือกอีกครั้ง หรือติดต่อเจ้าหน้าที่",
            "warning"
          );
        },
      });
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
  },
  mounted() {
    window.loading(false);
  },
};
</script>
<style lang="scss">
.uploadWrap {
  user-select: none;
  max-width: 400px;
  margin: 0 auto;
  label {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
  }

  #upload-photo {
    opacity: 0;
    position: absolute;
    display: none;
    z-index: -1;
  }

  .uploadBtn {
    background: cornflowerblue;
    border-radius: 6px;
    color: #ffffff;
    padding: 1px 5px;
    &:active {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }
  }

  .uploadArea {
    width: 100%;
    border: 2px dashed rgb(0 0 0 / 25%);
    height: 35px;
    margin: 5px auto;
    text-align: center;
    color: rgb(0 0 0 / 55%);
  }
  .remove {
    width: 100%;
    font-size: 14px;
    background: rgb(197, 39, 39);
    border-radius: 6px;
    cursor: pointer;
    color: #ffffff;
    text-align: center;
  }
}
</style>