<template>
  <div id="condition" class="condition container mt-4">
    <!-- <div
      v-if="
        (saleType === 'wholesale' || saleType === 'exclusivity') &&
        targetList.length > 0
      "
    >
      <WholesaleCalendar :dataList="targetList" :unit='"บาท"' />
      <hr class="row mt-0" />
    </div> -->
    <div
      v-if="privilegeTypeCode && saleType === 'retail'"
      :class="`shop-category p-1 my-2${
        privilegeTypeCode ? ` ${privilegeTypeCode}` : ''
      }`"
    >
      {{ privilegeTypeCode }}
    </div>
    <div
      v-if="(saleType === 'wholesale' || saleType === 'exclusivity') && bonus"
      class="bonus p-2 my-2"
    >
      <!-- {{ shopCatName }} -->
      <div class="header">
        คะแนนพิเศษ: <span>{{ bonus }}</span>
      </div>
      <div class="content">
        *เมื่อทำตามเป้าหมายรายเดือนที่บริษัทกำหนด<br />
        *การจัดกลุ่มลูกค้ามาจากยอดขายในปี 2565
      </div>
    </div>
    <div v-if="saleType === 'retail'" class="bonus p-2 my-2">
      {{ shopCatName }}
    </div>
    <b v-if="saleType === 'retail'">ขั้นตอนการรับคะแนน</b>
    <div v-if="saleType === 'retail'">
      1. กดแทป ‘คะแนน’ เพื่อแสดงรายการของใบเสร็จ<br />
      2. กดปุ่มสีฟ้า ‘กดยืนยันรับคะแนน’ ทางด้านขวาของรายการ ใบเสร็จ<br />
      3. กรอก 4 หลักสุดท้ายของเลขที่ใบเสร็จ
      ตามวันที่และเลขที่ของใบเสร็จที่ปรากฎ<br />
      4. กดปุ่ม ‘ตกลง’ เพื่อทำการส่งข้อมูลเข้าระบบ<br />
      5. ใบเสร็จที่ทำการกดตกลงแล้ว จะปรากฏปุ่มสีเทา มีสถานะ เป็น
      ‘รอการตรวจสอบ’<br />
      6. ในวันถัดไประบบจะแสดงปุ่มสีเขียวและคะแนนของใบเสร็จ
      ที่ผ่านระบบการตรวจสอบเรียบร้อยแล้ว
      <div class="text-center my-2">
        <img src="@/assets/how_retail.png" alt />
      </div>
    </div>
    <hr v-if="saleType === 'retail'" />
    <b v-if="saleType === 'retail'"
      >ข้อกำหนดและเงื่อนไข
      {{
        (saleType === "wholesale" || saleType === "exclusivity") && shopCatName
          ? `(${shopCatName})`
          : ""
      }}</b
    >
    <div v-if="saleType === 'retail'">
      1.ร้านค้าต้องลงทะเบียนเพื่อร่วมรายการก่อน โดยคะแนนจะเริ่มคำนวณให้ภายในเดือนที่ร้านค้าลงทะเบียน<br />
      2.รายการเสริมสุขแฟมิลี่ ซีซั่น 5 เริ่มตั้งแต่วันที่ 1 ธันวาคม 2566 ถึง 30 กันยายน 2567 โดยคะแนนจะเริ่มคำนวณตั้งแต่ 1 ธันวาคม 2566 ถึง 30 กันยายน 2567<br />
      3.ร้านค้าจะได้รับคะแนนจากทุกๆการสั่งซื้อสินค้า โดยคะแนนของแต่ละสินค้าเป็นไปตามประกาศของบริษัท ฯ<br />
      4.ร้านค้าสามารถแลกของรางวัลได้ทันทีที่คะแนนเพียงพอสำหรับของรางวัลที่ต้องการแลก โดยสามารถแลกของรางวัลได้ถึง วันที่ 31 ตุลาคม 2567<br />
      5.รายการนี้ขอสงวนสิทธิ์เริ่มคำนวณยอดขายภายในเดือนที่ร้านค้าทำการลงทะเบียนเท่านั้น ไม่สามารถคำนวณคะแนนย้อนหลังได้<br />
      6.ทางบริษัทขอสงวนสิทธิ์ในการที่ไม่รับเปลี่ยนหรือคืนสินค้า<br />
      7.ทางบริษัทขอสงวนสิทธิ์การเปลี่ยนแปลงของรางวัลที่มีมูลค่าใกล้เคียงกันโดยไม่ต้องแจ้งล่วงหน้า<br />
      8.คะแนนสะสมไม่สามารถเปลี่ยนเป็นเงินสด หรือ โอนให้กับร้านค้าที่ร่วมรายการอื่นๆ ได้<br />
      9.ของรางวัลที่มีมูลค่าเกิน 1,000 บาท ร้านค้าต้องเสียภาษีหัก ณ ที่จ่าย 3%<br />
      10.ขอสงวนสิทธิ์การคำนวณคะแนนสำหรับบิลที่มีการชำระเงินครบถ้วนแล้วเท่านั้น
    </div>
    <div v-else>
      1.ร้านค้าต้องลงทะเบียนเพื่อร่วมรายการก่อน โดยคะแนนจะเริ่มคำนวณให้ภายในเดือนที่ร้านค้าลงทะเบียน<br />
      2.รายการเสริมสุขแฟมิลี่ ซีซั่น 5 เริ่มตั้งแต่วันที่ 1 ธันวาคม 2566 ถึง 30 กันยายน 2567 โดยคะแนนจะเริ่มคำนวณตั้งแต่ 1 ธันวาคม 2566 ถึง 30 กันยายน 2567<br />
      3.ร้านค้าจะได้รับคะแนนจากทุกๆการสั่งซื้อสินค้า โดยคะแนนของแต่ละสินค้าเป็นไปตามประกาศของบริษัท ฯ<br />
      4.ร้านค้าสามารถแลกของรางวัลได้ทันทีที่คะแนนเพียงพอสำหรับของรางวัลที่ต้องการแลก โดยสามารถแลกของรางวัลได้ถึง วันที่ 31 ตุลาคม 2567<br />
      5.รายการนี้ขอสงวนสิทธิ์เริ่มคำนวณยอดขายภายในเดือนที่ร้านค้าทำการลงทะเบียนเท่านั้น ไม่สามารถคำนวณคะแนนย้อนหลังได้<br />
      6.ทางบริษัทขอสงวนสิทธิ์ในการที่ไม่รับเปลี่ยนหรือคืนสินค้า<br />
      7.ทางบริษัทขอสงวนสิทธิ์การเปลี่ยนแปลงของรางวัลที่มีมูลค่าใกล้เคียงกันโดยไม่ต้องแจ้งล่วงหน้า<br />
      8.คะแนนสะสมไม่สามารถเปลี่ยนเป็นเงินสด หรือ โอนให้กับร้านค้าที่ร่วมรายการอื่นๆ ได้<br />
      9.ของรางวัลที่มีมูลค่าเกิน 1,000 บาท ร้านค้าต้องเสียภาษีหัก ณ ที่จ่าย 3%<br />
      10.ขอสงวนสิทธิ์การคำนวณคะแนนสำหรับบิลที่มีการชำระเงินครบถ้วนแล้วเท่านั้น
    </div>
  </div>
</template>

<script>
// import WholesaleCalendar from "@/components/wholesaleCalendar";

export default {
  components: {
    // WholesaleCalendar,
  },
  computed: {
    shopCatName() {
      const { user_data } = this.$store.state;
      const shopCatName = user_data?.shopCatName;
      return shopCatName || "";
    },
    bonus() {
      const { user_data } = this.$store.state;
      const privilegeTypeCode = user_data?.privilegeTypeCode;
      switch (privilegeTypeCode) {
        case "1":
          return false;
        case "2":
          return "350";
        case "3":
          return "700";
        case "4":
          return "1,000";
        default:
          return "";
      }
    },
    privilegeTypeCode() {
      const { user_data } = this.$store.state;
      const privilegeTypeCode = user_data?.privilegeTypeCode;
      switch (privilegeTypeCode) {
        case "1":
          return "Bronze";
        case "2":
          return "Silver";
        case "3":
          return "Gold";
        case "4":
          return "Platinum";
        default:
          return "Retail";
      }
    },
    saleType() {
      const { user_data } = this.$store.state;
      const customerSaleType = user_data?.customerSaleType;
      return customerSaleType ? customerSaleType.toLowerCase() : "";
    },
    // targetList() {
    //   const { wholesaleTarget } = this.$store.state;
    // const newArray = [];
    // if (wholesaleTarget !== null) {
    //   const { months } = wholesaleTarget;
    //   if (months?.length > 0) {
    //     months.forEach((e) => {
    //       newArray.push({
    //         month: e.month,
    //         year: e.year,
    //         data: [
    //           {
    //             title: "เป้าหมาย",
    //             list: [
    //               {
    //                 title: "เป้าหมายเดือน " + this.$getDate(e.month - 1),
    //                 amount: e.targetReach,
    //               },
    //               { title: "เป้าหมายสะสม", amount: e.accumulateTargetReach },
    //             ],
    //           },
    //           {
    //             title: "ยอดซื้อ",
    //             list: [
    //               {
    //                 title: "ยอดซื้อเดือน " + this.$getDate(e.month - 1),
    //                 amount: e.currentTarget,
    //               },
    //               { title: "ยอดซื้อสะสม", amount: e.accumulateCurrentTarget },
    //             ],
    //           },
    //         ],
    //       });
    //     });
    //     newArray.sort((a, b) => {
    //       if (a.year === b.year) {
    //         return a.month - b.month;
    //       } else {
    //         return a.year - b.year;
    //       }
    //     });
    //   }
    // }
    //   return wholesaleTarget?.length > 0 ? wholesaleTarget : [];
    // },
  },
  mounted() {
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
$black: #000;
$gray: #ced3da;
$green: #b2d235;
$white: #fff;

.condition {
  font-size: 16px;
  hr {
    border-color: $gray;
    border-width: 5px;
  }
  .shop-category {
    background: $green;
    border-radius: 10px;
    color: $white;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }
  .bonus {
    background: $green;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    .header {
      font-size: 18px;
      span {
        background: $white;
        border: 1px solid $black;
        border-radius: 16px;
        font-weight: 700;
        padding: 1px 15px;
      }
    }
    .content {
      line-height: normal;
    }
  }
  .Platinum {
    color: #ffffff;
    background: #b6afa9;
  }
  .Gold {
    color: #ffffff;
    background: #fdd017;
  }
  .Silver {
    color: #ffffff;
    background: #c0c0c0;
  }
  .Bronze {
    color: #ffffff;
    background: #b87333;
  }
  .Retail {
    color: #189ad6;
    background: #ffffff;
  }
}
</style>