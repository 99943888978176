<template>
  <div class="addAddress root">
    <div v-if="state == 'error'" class="mt-2 text-center">
      กรุณาติดต่อเจ้าหน้าที่
    </div>
    <div v-if="state == 'loading'" class="box_item mt-2 p-2">
      <vue-content-loading
        v-for="i in 3"
        :key="i"
        class="mt-3"
        :weight="'100%'"
        :height="100"
      >
        <rect y="0" rx="4" ry="4" :width="'80%'" height="20" />
        <rect y="40" rx="4" ry="4" :width="'40%'" height="20" />
        <rect y="80" rx="4" ry="4" :width="'60%'" height="20" />
        <rect y="120" rx="4" ry="4" :width="'70%'" height="20" />
      </vue-content-loading>
    </div>
    <!-- <div class="d-flex align-items-center" style="background-color: #f0f6ff; height: 50px;">
      <i class="icon-left-open-big"></i>
      <span>กลับ</span>
    </div>-->
    <div v-if="data && state == 'ok'">
      <div v-if="!isAddress">
        <div class="pt-4">
          <slot name="addressHeader">
            <h4>การออกหนังสือรับรองการหักภาษี ณ ที่จ่าย</h4>
            <p style="font-size: 16px">
              การออกหนังสือรับรองการหักภาษี ณ ที่จ่าย
              ขอเอกสารตามการเปิดหน้าบัญชีกับเสริมสุข เช่น
              ถ้าเปิดหน้าบัญชีในนามนิติบุคคล (บจก./หจก.) ให้ใช้ ภ.พ.20 เท่านั้น
              หรือถ้าเปิดหน้าบัญชีในนามบุคคลให้
              ใช้สำเนาบัตรประชาชนเจ้าของร้านหรือชื่อตรงกันกับชื่อร้านค้า
              ในระบบของเสริมสุขเท่านั้น
            </p>
          </slot>
        </div>
        <hr />
        <h4>กรอกข้อมูลแบบฟอร์ม</h4>
        <div class="d-flex justify-content-center">
          <button
            id="CompanyTypeBtn"
            @click="selectType('personal')"
            :class="{
              'btn btn-primary': select_type == 'personal',
              'btn btn-light': select_type == 'corporation',
            }"
          >
            บุคคลธรรมดา
          </button>
          <button
            id="CompanyTypeBtn"
            @click="selectType('corporation')"
            :class="{
              'btn btn-primary': select_type == 'corporation',
              'btn btn-light': select_type == 'personal',
            }"
          >
            นิติบุคคล
          </button>
        </div>
        <div
          v-if="select_type == 'personal'"
          class="mt-3 justify-content-center"
        >
          <form id="addressForm" class="needs-validation" novalidate>
            <!-- citizen id input-->
            <div class="form-group">
              <label for="cidInput">รหัสบัตรประชาชน</label>
              <input
                type="tel"
                id="cidInput"
                name="citizenID"
                class="form-control"
                required
                pattern="[0-9]{13}"
                maxlength="13"
                v-model="data.taxCitizenId"
              />
              <div class="invalid-feedback">
                กรุณาใส่รหัสบัตรประชาชน (13 หลัก)
              </div>
            </div>

            <div class="mb-3">
              <div>อัพโหลด สำเนาบัตรประชาชน</div>
              <UploadVerifiedImage
                ref="uploadVerifiedImage"
                :imageUrl="data.cardImageUrl"
                v-on:result="
                  (result) => {
                    resultVerifiedImage = result;
                  }
                "
              />
              <div
                v-if="invalidFeedbackCardImage"
                class="invalid-feedback-card-image"
              >
                กรุณาเลือกไฟล์
              </div>
            </div>
            <!-- prefix input-->
            <div class="form-group">
              <label for="preInput">คำนำหน้าชื่อ</label>
              <!-- <input type="text" id="preInput" name="prefix" class="form-control" required v-model="data.taxPrefix"/> -->
              <select
                class="custom-select"
                name="prefix"
                id="preInput"
                required
                v-model="data.taxPrefix"
              >
                <option disabled selected value>เลือก</option>

                <option v-for="item in prefixs" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- first name input-->
            <div class="form-group">
              <label for="firstNameInput">ชื่อ</label>
              <input
                type="text"
                id="firstNameInput"
                name="firstName"
                class="form-control"
                required
                v-model="data.taxFirstName"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- last name input-->
            <div class="form-group">
              <label for="lastNameInput">นามสกุล</label>
              <input
                type="text"
                id="lastNameInput"
                name="lastName"
                class="form-control"
                required
                v-model="data.taxLastName"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- address number input-->
            <div class="form-group">
              <label for="addressNumberInput">บ้านเลขที่</label>
              <input
                type="text"
                id="addressNumberInput"
                name="addressNumber"
                class="form-control"
                required
                v-model="data.taxAddressNumber"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!--  moo input-->
            <div class="form-group">
              <label for="mooInput">หมู่</label>
              <input
                type="text"
                id="mooInput"
                name="moo"
                class="form-control"
                v-model="data.taxMoo"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!--  alley input-->
            <div class="form-group">
              <label for="alleyInput">ซอย</label>
              <input
                type="text"
                id="alleyInput"
                name="alley"
                class="form-control"
                v-model="data.taxAlley"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!--  road input-->
            <div class="form-group">
              <label for="roadInput">ถนน</label>
              <input
                type="text"
                id="roadInput"
                name="road"
                class="form-control"
                v-model="data.taxRoad"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <div class="form-group">
              <!-- ตำบล/แขวง -->
              <addressinput-subdistrict
                :inputClass="'form-control'"
                v-model="data.taxSubDistrict"
              />
              <!-- อำเภอ/เขต -->
              <addressinput-district
                :inputClass="'form-control'"
                required
                v-model="data.taxDistrict"
              />
              <!-- จังหวัด -->
              <addressinput-province
                :inputClass="'form-control'"
                required
                v-model="data.taxProvince"
              />
              <!-- รหัสไปรษณีย์ -->
              <addressinput-zipcode
                :inputClass="'form-control'"
                required
                pattern="[0-9]*"
                v-model="data.taxPostCode"
              />
            </div>

            <!-- process button -->
            <button
              id="processOkBtn"
              style="width: 100%"
              class="btn btn-primary my-2"
              type="submit"
            >
              ยืนยันข้อมูล
            </button>
          </form>
        </div>
        <div v-else class="mt-3 justify-content-center">
          <form id="addressForm" class="needs-validation" novalidate>
            <!-- citizen id input-->
            <div class="form-group">
              <label for="cidInput"
                >เลขที่ประจำตัวผู้เสียภาษีอากร (13หลัก)</label
              >
              <input
                type="tel"
                id="cidInput"
                name="citizenID"
                class="form-control"
                required
                pattern="[0-9]{13}"
                v-model="data.taxCitizenId"
              />
              <div class="invalid-feedback">
                กรุณาใส่เลขที่ประจำตัวผู้เสียภาษีอากร (13หลัก)
              </div>
            </div>

            <!-- Upload PP20 -->
            <div class="mb-3">
              <div>อัพโหลด ภ.พ.20</div>
              <UploadVerifiedImage
                ref="uploadVerifiedImage"
                :imageUrl="data.cardImageUrl"
                v-on:result="
                  (result) => {
                    resultVerifiedImage = result;
                  }
                "
              />
              <div
                v-if="invalidFeedbackCardImage"
                class="invalid-feedback-card-image"
              >
                กรุณาเลือกไฟล์
              </div>
            </div>

            <!-- company type id input-->
            <div class="form-group">
              <label for="companyTypeInput">รูปแบบการจดทะเบียนธุรกิจ</label>
              <select
                class="custom-select"
                name="companyType"
                id="companyTypeInput"
                required
                v-model="data.taxCompanyType"
              >
                <option disabled selected value>เลือก</option>

                <option v-for="item in companyType" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>
              <!-- <input
                  type="text"
                  id="companyTypeInput"
                  name="companyType"
                  class="form-control"
                  required
                  v-model="data.taxCompanyType"
                />-->
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- name input-->
            <div class="form-group">
              <label for="nameInput">ชื่อสถานประกอบการในการจดทะเบียน</label>
              <input
                type="text"
                id="nameInput"
                name="name"
                class="form-control"
                required
                v-model="data.taxCompanyName"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- address number input-->
            <div class="form-group">
              <label for="addressNumberInput">เลขที่</label>
              <input
                type="text"
                id="addressNumberInput"
                name="addressNumber"
                class="form-control"
                required
                v-model="data.taxAddressNumber"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- building input-->
            <div class="form-group">
              <label for="buildingInput">อาคาร</label>
              <input
                type="text"
                id="buildingInput"
                name="building"
                class="form-control"
                v-model="data.taxBuilding"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- floor input-->
            <div class="form-group">
              <label for="floorInput">ชั้น</label>
              <input
                type="text"
                id="floorInput"
                name="floor"
                class="form-control"
                v-model="data.taxFloor"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!--  moo input-->
            <div class="form-group">
              <label for="mooInput">หมู่</label>
              <input
                type="text"
                id="mooInput"
                name="moo"
                class="form-control"
                v-model="data.taxMoo"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!--  alley input-->
            <div class="form-group">
              <label for="alleyInput">ซอย</label>
              <input
                type="text"
                id="alleyInput"
                name="alley"
                class="form-control"
                v-model="data.taxAlley"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!--  road input-->
            <div class="form-group">
              <label for="roadInput">ถนน</label>
              <input
                type="text"
                id="roadInput"
                name="road"
                class="form-control"
                v-model="data.taxRoad"
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <div class="form-group">
              <!-- ตำบล/แขวง -->
              <addressinput-subdistrict
                :inputClass="'form-control'"
                v-model="data.taxSubDistrict"
              />
              <!-- อำเภอ/เขต -->
              <addressinput-district
                :inputClass="'form-control'"
                required
                v-model="data.taxDistrict"
              />
              <!-- จังหวัด -->
              <addressinput-province
                :inputClass="'form-control'"
                required
                v-model="data.taxProvince"
              />
              <!-- รหัสไปรษณีย์ -->
              <addressinput-zipcode
                :inputClass="'form-control'"
                required
                pattern="[0-9]*"
                v-model="data.taxPostCode"
              />
            </div>

            <!-- process button -->
            <button
              id="processOkBtn"
              style="width: 100%"
              class="btn btn-primary my-2"
              type="submit"
            >
              ยืนยันข้อมูล
            </button>
          </form>
        </div>
      </div>

      <!-- comfirm page -->
      <div
        v-else
        class="d-flex flex-column justify-content-between"
        style="height: calc(100vh - 80px)"
      >
        <div class="mt-5 p-1">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <slot name="confirmHeader">
                <div class="font-weight-bold" style="font-size: 20px">
                  ที่อยู่สำหรับการออกหนังสือ
                </div>
                <div class="font-weight-bold" style="font-size: 20px">
                  หักภาษี ณ ที่จ่าย
                </div>
              </slot>
            </div>

            <div style>
              <button
                id="editBtn"
                class="btn btn-light d-flex align-items-center"
                style="border: 1px solid"
                @click="edit()"
              >
                <img
                  src="@/assets/icon/edit-tools.svg"
                  style="padding-right: 4px"
                />
                <span>แก้ไข</span>
              </button>
            </div>
          </div>

          <div v-if="data.taxType == 'personal'">
            <p>
              ผู้รับ: {{ data.taxPrefix }} {{ data.taxFirstName }}
              {{ data.taxLastName }}
            </p>
            <p>เลขบัตรประชาชน: {{ data.taxCitizenId }}</p>
            <p>
              ที่อยู่: {{ data.taxAddressNumber }} หมู่ {{ data.taxMoo }} ซอย
              {{ data.taxAlley }} ถนน {{ data.taxRoad }} เขต
              {{ data.taxDistrict }} แขวง {{ data.taxSubDistrict }} จังหวัด{{
                data.taxProvince
              }}
            </p>
            <p>รหัสไปรษณีย์: {{ data.taxPostCode }}</p>

            <div class="wrapStatusCardImage">
              <div class="title">อัพโหลดสำเนาบัตรประชาชน:</div>
              <span v-if="data.cardImageUrl"> อัพโหลดแล้ว</span
              ><span v-else class="notUpload"> ยังไม่อัพโหลด</span>
            </div>
          </div>
          <div v-else>
            <p>{{ data.taxCompanyType }} {{ data.taxCompanyName }}</p>
            <p>เลขที่ประจำตัวผู้เสียภาษีอากร: {{ data.taxCitizenId }}</p>
            <p>
              ที่อยู่: {{ data.taxAddressNumber }} อาคาร
              {{ data.taxBuilding }} ชั้น {{ data.taxFloor }} หมู่
              {{ data.taxMoo }} ซอย {{ data.taxAlley }} ถนน
              {{ data.taxRoad }} เขต {{ data.taxDistrict }} แขวง
              {{ data.taxSubDistrict }} จังหวัด{{ data.taxProvince }}
            </p>
            <p>รหัสไปรษณีย์: {{ data.taxPostCode }}</p>

            <div class="wrapStatusCardImage">
              <div class="title">อัพโหลด ภ.พ.20:</div>
              <span v-if="data.cardImageUrl"> อัพโหลดแล้ว</span
              ><span v-else class="notUpload"> ยังไม่อัพโหลด</span>
            </div>
          </div>
          <div v-if="!data.cardImageUrl" class="messageMustUpload">
            กรุณาอัพโหลดสำเนาบัตรประชาชน หรือ
            <span class="d-inline-block">ภ.พ. 20</span> <br />
            โดยกดปุ่ม แก้ไข
          </div>
        </div>

        <div
          @click="$emit('confirm-address', null)"
          class="text-center comfirmBtn py-4"
          v-if="data.cardImageUrl"
        >
          <slot name="confirmButton">
            <div>กดยืนยันที่อยู่</div>
            <div>สำหรับการออกหนังสือหักภาษี ณ ที่จ่าย</div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import 'vue-thailand-address/dist/vue-thailand-address.css';
import "@/assets/styles/vue-thailand-address.css";
import VueContentLoading from "vue-content-loading";
import UploadVerifiedImage from "@/components/UploadVerifiedImage";

export default {
  name: "addAddress",
  components: {
    VueContentLoading,
    UploadVerifiedImage,
  },
  data() {
    return {
      data: null,
      isAddress: null,
      select_type: "personal",
      prefixs: ["นาย", "นาง", "นางสาว"],
      companyType: [
        "ห้างหุ้นส่วนสามัญจดทะเบียน",
        "ห้างหุ้นส่วนจำกัด",
        "บริษัทจำกัด",
        "บริษัทมหาชนจำกัด",
      ],
      state: "loading",
      resultVerifiedImage: {},
      invalidFeedbackCardImage: false,
    };
  },
  watch: {
    isAddress() {
      console.log(this.isAddress);
      if (this.isAddress === false) {
        this.$nextTick(() => {
          // document.getElementById("addressForm").removeEventListener("submit");
          this.initValidForm();
        });
      }
    },
  },
  methods: {
    initValidForm() {
      const form = document.getElementById("addressForm");
      // Loop over them and prevent submission

      form.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
          event.stopPropagation();

          // check not found card image
          this.invalidFeedbackCardImage =
            (!this.data.cardImageUrl && !this.resultVerifiedImage?.file) ||
            false;

          if (
            form.checkValidity() !== false &&
            !this.invalidFeedbackCardImage
          ) {
            // when form validated
            this.update();
          }

          document.getElementById("address_modal").scrollTop = 300;
          form.classList.add("was-validated");
        },
        false
      );
    },
    selectType(t) {
      this.select_type = t;
      this.data.taxType = t;
      // this.resultVerifiedImage = null;
      // this.$refs.uploadVerifiedImage.reset();
      this.$nextTick(() => {
        // document.getElementById("addressForm").removeEventListener("submit");
        this.initValidForm();
      });
    },
    setConfirm() {
      this.confirm = true;
    },
    edit() {
      this.isAddress = false;
    },
    async fetchData() {
      // get address info
      return await fetch(`${process.env.VUE_APP_API_URL}/users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async getData() {
      try {
        const res = await this.fetchData();
        console.log("fetchData status:", res.status);

        if (res.status != 200) {
          throw "no case";
        }

        let body = [];

        body = await res.json();

        this.data = body || null;

        console.log("fetchData body:", body);
        if (this.data.taxType == "personal") {
          this.select_type = "personal";
          this.isAddress = true;
        } else if (this.data.taxType == "corporation") {
          this.select_type = "corporation";
          this.isAddress = true;
        } else {
          this.data.taxType = "personal";
          this.isAddress = false;
        }

        this.state = "ok";
      } catch (error) {
        this.state = "error";
        console.log(error);
      }
    },
    async fetchUpdate() {
      const formData = new FormData();

      for (let key in this.data) {
        formData.append(key, this.data[key]);
      }

      if (this.resultVerifiedImage?.file) {
        formData.append(
          "cardImage",
          this.resultVerifiedImage.file,
          this.resultVerifiedImage.fileName
        );
      }

      return await fetch(`${process.env.VUE_APP_API_URL}/users`, {
        method: "PATCH",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${this.$JWT}`,
        },
        body: formData,
      });
    },
    async update() {
      let body = {};
      try {
        window.loading(true);
        const res = await this.fetchUpdate();
        console.log("fetchRedeem status:", res.status);
        body = await res.json();
        this.data = body || null;
        this.resultVerifiedImage = {};
        console.log("fetchRedeem body:", body);
        window.loading(false);

        switch (res.status) {
          case 200:
            this.isAddress = true;
            break;
          default:
            console.log("fetchRedeem: no case");
            throw "no case";
        }
      } catch (error) {
        window.loading(false);
        console.log(error);
        this.Swal.fire("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่", "error");
      }
    },
  },
  beforeMount() {
    window.scrollTop = 0;
  },
  // watch: {
  //   pinIn
  // },
  mounted() {
    this.getData();
    window.loading(false);
    // window.loading(true);
  },
};
</script>

<style lang="scss" scoped>
.back {
  display: inline-block;
  position: fixed;
  top: 6rem;
  margin-left: 10px;
  background: #d8d8d84b;
  font-size: 16px;
  border-radius: 30px;
}

.back a {
  color: #7b7b7b;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.2s;
}

// .root {
//   font-size: 16px;
//   position: relative;
// }

.rw_point {
  padding: 3px 10px 3px 25px;
  text-align: center;
  font-size: 20px;
  right: 0;
  color: rgb(255, 255, 255);
  background: #b2d235;
  position: absolute;
  margin-top: -30px;
  font-weight: 500;
  z-index: 1;
  min-width: 100px;
  height: 45px;
  border-radius: 25px 0 0 25px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.rw_title {
  margin: 20px 0 0 0;
  font-size: 20px;
}

.rw_des {
  line-height: 22px;
}

.separate {
  margin: 0 auto;
  width: 60%;
  border-bottom: 1px solid #f0f0f0;
}

#CompanyTypeBtn {
  margin: 2px;
  font-size: 16px;
  width: 45%;
  padding: 5px 0;
}

#addAddressBtn,
#okBtn {
  font-size: 16px;
  width: 80%;
  padding: 5px 0;
}

.addAddress {
  width: 100%;
  overflow-y: auto;
}

.term_content {
  overflow: scroll;
  max-height: 400px;
  line-height: 1.1em;
}

.qtyTxt {
  font-size: 18px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 12px;
}

input#quantityInput {
  height: 35px;
  letter-spacing: 5px;
  font-size: 16px;
  padding: 0;
  text-align: center;
}

.action_segment {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid rgb(248, 248, 248);
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  max-width: 450px;
}

.content_segment {
  min-height: 100vh;
  padding-bottom: 140px;
}

// lib
label.th-address-label {
  width: 100%;
}

p {
  margin-bottom: 0rem;
}

.comfirmBtn {
  cursor: pointer;
  font-size: 18px;
  color: #ffffff;
  background: #189ad6;
  border-radius: 8px;
}

.wrapStatusCardImage {
  display: flex;
  font-size: 16px;
  border-top: 1px solid #ddd;
  line-height: 30px;
  > .title {
    margin-right: 10px;
  }

  > span {
    font-weight: bold;
  }

  .notUpload {
    color: #cf3931;
  }
}

.invalid-feedback-card-image {
  font-size: 14px;
  color: #ff4136;
}

.messageMustUpload {
  text-align: center;
  color: #ff4136;
  line-height: 25px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
