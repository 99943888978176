<template>
  <div class="confirmPoint root">
    <div class="container text-center">
      <div class="row back pl-2">
        <router-link to="invoices">
          <i class="icon-left-open-big"></i> ย้อนกลับ
        </router-link>
      </div>
      <div class="box p-4">
        <div class="title mt-1">วิธีรับคะแนนสะสม</div>
        <div class>
          กรุณากรอกเลข 4 หลักสุดท้ายของเลขที่ใบเสร็จ
          เมื่อคุณยืนยันการรับคะแนนแล้ว ไม่สามารถคืนสินค้าที่ทำการจัดซื้อมาแล้ว
        </div>
        <div class="sparate my-4"></div>
        <form id="form" class="text-left" novalidate>
          <div class="mb-3">
            <b>วันที่:</b>
            {{ transDate }}
          </div>
          <div class="form-inline">
            <b>เลขที่ใบเสร็จ</b>
            <div class="form-group invoice_no">
              <div class="refOrder">
                <span class="text-right pr-1" style="width: 70%"
                  >{{ $invoToString(refOrder.slice(0, -4)) }} -</span
                >
                <input
                  type="tel"
                  id="refCodeInput"
                  name="refCode"
                  class="form-control"
                  pattern="[0-9]{4}"
                  maxlength="4"
                  required
                  style="width: 30%"
                />
                <div class="invalid-feedback">กรุณาใส่ตัวเลข 4 หลัก</div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary mx-auto mt-4 w-100"
            data-tag="collect"
          >
            ตกลง
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "confirmPoint",
  props: ["transDate", "invoiceID", "refOrder"],
  data() {
    return {};
  },
  methods: {
    initValidForm() {
      const form = document.getElementById("form");
      // Loop over them and prevent submission
      form.addEventListener(
        "submit",
        (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (form.checkValidity() !== false) {
            // when form validated
            this.ok(form);
          }
          form.classList.add("was-validated");
        },
        false
      );
    },

    async ok(form) {
      try {
        window.loading(true);
        const formData = new FormData(form);

        // get shop info
        const res = await fetch(
          process.env.VUE_APP_API_URL + `/invoices/${this.invoiceID}/collect`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$JWT}`,
            },
            body: formData,
          }
        );
        window.loading(false);
        console.log("fetchComfirmPoint status:", res.status);
        // check status for redirect some page or set params
        let body = [];
        body = await res.json();

        switch (res.status) {
          case 200:
            if (body.status != "SUCCESS") {
              this.$Error();
              break;
            }
            this.alertMsg(body.status);
            break;
          case 422:
            // if in case give error
            this.alertMsg(body.status);
            break;
          default:
            console.log("no case:");
            this.$Error();
        }
      } catch (error) {
        console.log(error);
        this.$Error();
        window.$pushEvent("comfirm_point", "error", error);
      } finally {
        window.loading(false);
      }
    },
    alertMsg(code) {
      window.$pushEvent("comfirm_point", "collect", code);
      switch (code) {
        case "SUCCESS":
          this.Swal.fire("ส่งข้อมูลสำเร็จ", "", "success").then(() => {
            this.$router.push("/invoices").catch(() => {});
          });
          break;
        case "INVALID_INVOICE":
          this.Swal.fire(
            "ข้อมูลใบเสร็จไม่ถูกต้อง",
            "กรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่",
            "error"
          );
          break;
        case "DUPLICATE":
          this.Swal.fire(
            "ใบเสร็จนี้มีการแลกคะแนนแล้ว",
            "กรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่",
            "warning"
          );
          break;
        default:
          this.$Error();
          window.$pushEvent("comfirm_point", "error", code);
      }
    },
  },
  mounted() {
    if (!this.$readOnly) this.initValidForm();
  },
};
</script>

<style  scoped>
.root {
  font-size: 16px;
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-size: 0px;
  padding-right: 5px;
}

.root {
  color: #2b2b2b;
  background-image: linear-gradient(167deg, #98eeff, #00c0ff 95%);
  min-height: 100vh;
  padding-bottom: 10px;
}

.back {
  padding-top: 20px;
}

.back a {
  color: #444444;
}

.title {
  font-size: 30px;
}

.box {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 10px;
}

.sparate {
  height: 6px;
  background: #b2d235;
}

.invoice_no {
  letter-spacing: 1px;
  font-size: 15px;
}

.invoice_no input {
  letter-spacing: 4px;
  text-align: center;
  /* text-indent: 0.9em; */
}

.refOrder {
  flex-wrap: wrap;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 25px;
  position: relative;
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  position: absolute;
  bottom: 0;
  right: 0;
  left: unset;
}

@media screen and (max-width: 360px) {
  .root {
    font-size: 14px;
  }
  .invoice_no {
    font-size: 12px;
  }
}
</style>
