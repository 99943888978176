<template>
  <div class="result-modal-container root" data-testid="result-modal">
    <div v-if="state == 'error'" class="mt-2 text-center">กรุณาติดต่อเจ้าหน้าที่</div>
    <div v-if="state == 'loading'" class="box_item mt-2 p-2">
      <vue-content-loading v-for="i in 3" :key="i" class="mt-3" :weight="'100%'" :height="100">
        <rect y="0" rx="4" ry="4" :width="'80%'" height="20" />
        <rect y="40" rx="4" ry="4" :width="'40%'" height="20" />
        <rect y="80" rx="4" ry="4" :width="'60%'" height="20" />
        <rect y="120" rx="4" ry="4" :width="'70%'" height="20" />
      </vue-content-loading>
    </div>
    <div v-if="state == 'ok'">
      <!-- comfirm page -->
      <div class="container">
        <div class="p-1 text-center">
          <img v-lazy="require('@/assets/check-mark-1.svg')" height="130px" width="130px" />
          <div>
            ยืนยันการลุ้นของรางวัลสำเร็จ
            <br />ขอให้โชคดีนะคะ :)
          </div>
        </div>
        <b-button
          @click="$emit('confirm-modal',null)"
          variant="primary"
          pill
          block
        >กลับสู่หน้าลุ้นรางวัล</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import VueContentLoading from "vue-content-loading";

export default {
  name: "resultModal",
  components: {
    VueContentLoading,
    "b-button": BButton,
  },
  data() {
    return {
      state: "ok",
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  mounted() {
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
.separate {
  margin: 0 auto;
  width: 60%;
  border-bottom: 1px solid #f0f0f0;
}

.result-modal-container {
  width: 100%;
  .container {
    img {
      margin: 30px 0 40px;
    }
    button {
      margin: 25px 0;
    }
  }
}

.term_content {
  overflow: scroll;
  max-height: 400px;
  line-height: 1.1em;
}

.action_segment {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid rgb(248, 248, 248);
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  max-width: 450px;
}

.content_segment {
  min-height: 100vh;
  padding-bottom: 140px;
}
</style>
