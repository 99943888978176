<template>
  <div class="redeemDetail root">
    <div class="content_segment">
      <img v-lazy="data.imageUrl" width="100%" alt="..." />
      <div class="rw_point">{{ $toLocal(data.redeemPoint) }} P</div>
      <div class="container">
        <div v-html="data.description" class="content"></div>
      </div>
    </div>

    <div class="action_segment">
      <div class="separate"></div>
      <div class="row">
        <div class="col-4">
          <span class="align-middle">จำนวน</span>
        </div>
        <div class="col-5 text-center offset-3">
          <div @click="openEnterQty" class="qtyTxt">{{ quantity }}</div>
        </div>
      </div>
      <div class="separate"></div>
      <div class="w-100 text-center">
        <b-button
          v-if="canRedeemPending || canRedeem"
          :disabled="!(canRedeem || canRedeemPending) || $readOnly"
          variant="primary"
          block
          pill
          @click="redeemClick()"
          data-tag="redeem"
          >ลุ้นของรางวัล</b-button
        >
        <b-button
          v-if="!(canRedeem || canRedeemPending)"
          disabled
          variant="light"
          block
          pill
          >คะแนนไม่เพียงพอ</b-button
        >
      </div>
    </div>

    <!-- confirm modal -->
    <b-modal id="confirm_modal" hide-footer centered size="sm">
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline"></i>
      </template>
      <div class="d-block text-center">
        <div class="modal_content_section container">
          <b-row>
            <b-col cols="4">คุณมี</b-col>
            <b-col cols="4">{{ $toLocal(point) }}</b-col>
            <b-col cols="4">คะแนน</b-col>
          </b-row>
          <b-row>
            <b-col cols="4">ใช้</b-col>
            <b-col cols="4">{{ $toLocal(usePoint) }}</b-col>
            <b-col cols="4">คะแนน</b-col>
          </b-row>
          <b-row>
            <b-col cols="4">คงเหลือ</b-col>
            <b-col cols="4">{{ $toLocal(remainPoint) }}</b-col>
            <b-col cols="4">คะแนน</b-col>
          </b-row>
          <b-row v-if="!canRedeem && canRedeemPending">
            <b-col>
              <em>*ใช้คะแนนไม่ผ่านเป้า 85%</em>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="modal_button_section container">
        <b-button
          variant="primary"
          block
          pill
          @click="openTermModal()"
          data-tag="confirm"
          >ยืนยันการใช้คะแนน</b-button
        >
      </div>
    </b-modal>
    <!-- End confirm modal -->

    <!-- term modal -->
    <b-modal id="term_modal" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline"></i>
      </template>
      <div class="d-block">
        <h4 class="text-center">เงื่อนไขการร่วมกิจกรรมและการแจกรางวัล</h4>
        <div class="term_content">
          <p>
            1.ผู้ร่วมกิจกรรมทำการเพิ่มเพื่อนใน Line Official Account
            @Sermsukfamily ลงทะเบียนด้วยรหัสลูกค้าของเสริมสุข
            และซื้อสินค้าจากบริษัท เสริมสุข จำกัด (มหาชน)
            เพื่อรับคะแนนตามแต่ละสินค้า โดยการกดรับคะแนนด้วยเลขที่ 4
            ตัวสุดท้ายของใบเสร็จสำหรับร้านค้าทั่วไป
            หรือรับคะแนนอัตโนมัติสำหรับร้านค้าส่ง
          </p>
          <p>
            2.ร้านค้าที่ต้องการร่วมกิจกรรมสามารถใช้คะแนนที่มีสะสมตั้งแต่วันที่ 1
            ธันวาคม 2566 ถึงวันที่ 30 กันยายน 2567
            แลกสิทธิ์ในการร่วมกิจกรรมโดยใช้ 1 คะแนน แลกได้ 1 สิทธิ์
            สามารถใช้คะแนนแลกได้ตั้งแต่วันที่ 1 ตุลาคม 2567 ถึงวันที่ 31 ตุลาคม
            2567 ตามคะแนนที่ร้านค้ามีไม่มีจำกัดจำนวนสิทธิ์
          </p>
          <p>
            3.จับรางวัลวันที่ 15 พฤศจิกายน 2567 เวลา 11:00 น.
            ประกาศรายชื่อผู้โชคดีวันที่ 15 พฤศจิกายน 2567 เวลา 15:00 น. ทาง Line
            Official Account Sermsuk Family
          </p>
          <p>
            4.ของรางวัลรวมมูลค่ากว่า 210,000 บาท ได้แก่ <br />· ทองคำแท่ง มูลค่า
            100,000 บาท จำนวน 1 รางวัล <br />· ทองคำแท่ง มูลค่า 10,000 บาท จำนวน
            3 รางวัล <br />· ทองคำแท่ง มูลค่า 5,000 บาท จำนวน 16 รางวัล
          </p>
          <p>
            5.ในการจับชิ้นส่วนหากปรากฏชื่อผู้โชคดีแล้วจะต้องรับรางวัลตามที่กำหนดไว้
            รางวัลที่ได้รับ
            ของรางวัลไม่สามารถเปลี่ยนเป็นเงินสดหรือของรางวัลอื่นได้
            และไม่สามารใช้ร่วมกับส่วนลดหรือโปรโมชั่นอื่นๆ ได้
            รวมทั้งไม่มีการจ่ายเงินเป็นส่วนประกอบแต่อย่างใด
          </p>
          <p>
            6.สอบถามข้อมูลเพิ่มเติม โทร. 02-783-9000 (วันจันทร์-ศุกร์ เวลา
            08:00-18:00 น. ยกเว้นวันหยุดนักขัตฤกษ์)
          </p>
        </div>
      </div>
      <div class="text-center">
        <button
          id="okBtn"
          class="btn btn-primary mt-3"
          @click="openAddAddress()"
          data-tag="term_confirm"
        >
          ยอมรับและแลกของรางวัล
        </button>
      </div>
    </b-modal>
    <!-- End term modal -->

    <!-- address modal -->
    <b-modal id="address_modal" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline"></i>
      </template>
      <AddAddress v-on:confirm-address="confirmAddress">
        <template v-slot:addressHeader>
          <h2 class="text-center">รายละเอียดข้อมูลในการแลกสิทธิ์</h2>
        </template>
        <template v-slot:confirmHeader>
          <div class="font-weight-bold" style="font-size: 20px">
            ข้อมูลในการแลกสิทธิ์
          </div>
        </template>
        <template v-slot:confirmButton>
          <div data-tag="confirm_address">กดยืนยันข้อมูล</div>
        </template>
      </AddAddress>
    </b-modal>
    <!-- End address modal -->

    <!-- result modal -->
    <b-modal id="result_modal" hide-header hide-footer centered size="sm">
      <ResultModal v-on:confirm-modal="confirmModal" />
    </b-modal>
    <!-- End result modal -->

    <transition name="list">
      <PinCodeInput
        v-if="enterPincode"
        v-on:already-pin="alreadyPin"
        v-on:close="closePinInput"
        :title="'กรุณาใส่ PIN'"
        :reset_pin="'ลืม PIN ?'"
        v-on:reset-pin="resetPin"
        :pin_length="6"
      />
    </transition>
    <transition name="list">
      <NumberInput
        v-if="enterQty"
        :title="'จำนวนสิทธิ์'"
        v-on:already="alreadyQty"
        v-on:close="closeQtyInput"
      >
        <template v-slot:submitButton>ตกลง</template>
      </NumberInput>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import PinCodeInput from '@/components/pinCodeInput'
import NumberInput from '@/components/numberInput'
import ResultModal from '@/components/resultModal'
import AddAddress from '@/components/addAddress'

export default {
  name: 'redeemDetail',
  props: {
    data: Object,
  },
  components: { PinCodeInput, NumberInput, ResultModal, AddAddress },
  data() {
    return {
      quantity: 1,
      enterPincode: false,
      enterQty: false,
      redeemText: '',
    }
  },
  computed: {
    pendingPoint() {
      return this.$userData.pending_point
    },
    canRedeem() {
      return this.$userData.point >= this.data.redeemPoint * this.quantity
    },
    canRedeemPending() {
      if (
        this.$userData.customerSaleType === 'WHOLESALE' &&
        this.$userData.point < this.data.redeemPoint &&
        this.pendingPoint >= this.data.redeemPoint * this.quantity
      ) {
        return true
      }
      return false
    },
    point() {
      if (this.canRedeem) {
        return this.$userData.point
      } else if (this.canRedeemPending) {
        return this.pendingPoint
      }
      return 0
    },
    usePoint() {
      return this.data.redeemPoint * this.quantity || 0
    },
    remainPoint() {
      return this.point - this.usePoint
    },
  },
  methods: {
    async joinRedeem(lucky_draw_id, qty, pincode) {
      let body = {}
      try {
        window.loading(true)
        const res = await fetch(
          process.env.VUE_APP_API_URL +
            '/lucky_draws/' +
            lucky_draw_id +
            '/join',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.$JWT}`,
            },
            body: JSON.stringify({
              quantity: qty,
              pincode: pincode.toString(),
            }),
          }
        )
        body = await res.json()
        window.loading(false)
        window.$pushEvent('lucky_draws', 'response', 'join:' + res.status)
        switch (res.status) {
          case 200: {
            this.$bvModal.show('result_modal')
            return
          }
          case 422:
            this.alertMsg(body.message)
            break
          case 403:
            this.alertMsg(body.message)
            break
          default:
            console.log('no case status')
            this.alertMsg('error')
        }
      } catch (error) {
        window.loading(false)
        console.log(error)
        this.alertMsg('error')
      }
    },
    async alertMsg(status) {
      window.$pushEvent('lucky_draws', 'alert', 'join_msg:' + status)
      switch (status) {
        case 'POINT_NOT_ENOUGH':
          this.Swal.fire(
            'คะแนนไม่เพียงพอ',
            'กรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่',
            'warning'
          )
          break
        case 'INVALID_PINCODE':
          this.Swal.fire({
            title: 'PIN ไม่ถูกต้อง',
            text: 'กรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#eaa200',
            cancelButtonColor: '#a7a7a7',
            confirmButtonText: 'Reset Pin',
            cancelButtonText: 'กลับ',
          }).then((result) => {
            if (result.value) {
              this.resetPin()
            }
          })
          break
        case 'WHOLESALE_REACHED_TARGET':
          this.Swal.fire({
            html: `ขออภัยไม่สามารถแลกของรางวัลได้<br>เนื่องจากยอดสั่งซื้อสินค้าไม่ผ่านตามเป้าหมายที่กำหนดไว้ แต่คะแนนของท่านมีค่า สามารถเก็บคะแนนไว้สำหรับร่วมกิจกรรม<br>"เสริมสุขแฟมิลี่ Lucky Surprise" เดือน ก.ย.นี้`,
            icon: 'warning',
          })
          break
        case 'WHOLESALE_DATE_LIMIT':
          this.Swal.fire({
            html: 'สามารถแลกของรางวัลได้เมื่อจบรายการ<br>ตั้งแต่วันที่ 5-31 สิงหาคม 2563<br>(คะแนนมีอายุ 30 วันหลังจากจบรายการ)',
            icon: 'warning',
          })
          break
        default:
          this.Swal.fire({
            html: `แลกของรางวัลไม่ได้ในขณะนี้<br>กรุณาเข้าใช้ใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่`,
            icon: 'warning',
          })
      }
    },
    redeemClick() {
      // check condition redeem
      // if (this.$store.state.user_data.customerSaleType == "WHOLESALE") {
      //   if (!this.data.redeemAble) {
      //     this.alertMsg("WHOLESALE_DATE_LIMIT");
      //     return;
      //   }
      //   if (this.$store.state.user_data.isReachedTarget == 0) {
      //     this.alertMsg("WHOLESALE_REACHED_TARGET");
      //     return;
      //   }
      // }

      // check correct qty
      if (this.quantity < 1) {
        this.Swal.fire('', 'จำนวนขั้นต่ำคือ 1', 'warning') // alert
        this.quantity = 1 // reset qty
      } else {
        this.$bvModal.show('confirm_modal')
      }
    },
    openTermModal() {
      this.$bvModal.show('term_modal')
      this.$bvModal.hide('confirm_modal')
    },
    openAddAddress() {
      this.$bvModal.show('address_modal')
      this.$bvModal.hide('term_modal')
    },
    confirmAddress() {
      this.$bvModal.hide('address_modal')
      this.openEnterPin()
    },
    openEnterPin() {
      this.enterPincode = true
    },
    alreadyPin(pinCodeInput) {
      this.enterPincode = false
      window.$pushEvent('lucky_draws', 'event', 'already_pin')
      this.joinRedeem(this.data.id, this.quantity, pinCodeInput)
    },
    closePinInput(val) {
      if (val) {
        this.enterPincode = false
      }
    },
    resetPin() {
      this.$router.replace({
        name: 'otp',
        params: {
          route_back: this.$route,
          route_after_success: {
            name: 'set-pin',
            params: {
              route_after_success: this.$route,
            },
          },
        },
      })
    },
    openEnterQty() {
      this.enterQty = true
    },
    alreadyQty(val) {
      this.enterQty = false
      this.quantity = val
    },
    closeQtyInput(val) {
      if (val) {
        this.enterQty = false
      }
    },
    confirmModal() {
      this.$bvModal.hide('result_modal')
      this.$router.push({ name: 'lucky-draws' })
    },
  },
  beforeMount() {
    window.scrollTo(0, 0)
  },
  // watch: {
  //   pinIn
  // },
  mounted() {
    if (this.data) {
      window.loading(false)
    } else {
      this.$router.push({ name: 'lucky-draws' })
    }
  },
}
</script>

<style lang="scss" scoped>
.root {
  font-size: 16px;
  position: relative;
}

.rw_point {
  padding: 3px 10px 3px 25px;
  text-align: center;
  font-size: 20px;
  right: 0;
  color: rgb(255, 255, 255);
  background: #b2d235;
  position: absolute;
  margin-top: -30px;
  font-weight: 500;
  z-index: 1;
  min-width: 100px;
  height: 45px;
  border-radius: 25px 0 0 25px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.rw_title {
  margin: 20px 0 0 0;
  font-size: 20px;
}

.rw_des {
  line-height: 22px;
}

.separate {
  margin: 1em auto;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
}

.modal_content_section {
  overflow: scroll;
  max-height: 400px;
  margin-top: 40px;
  // line-height: 1.1em;
  .row {
    :first-child {
      text-align: left;
    }
    :last-child {
      text-align: right;
    }
  }
}

.modal_button_section {
  margin-top: 20px;
  text-align: center;
}

.qtyTxt {
  font-size: 18px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 12px;
}

input#quantityInput {
  height: 35px;
  letter-spacing: 5px;
  font-size: 16px;
  padding: 0;
  text-align: center;
}

.action_segment {
  padding: 0 15px 10px 15px;
  // border-top: 1px solid rgb(248, 248, 248);
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  max-width: 450px;
}

.content_segment {
  min-height: 100vh;
  padding-bottom: 140px;
  .container {
    .title {
      font-weight: bold;
      padding-top: 20px;
    }
    .content {
      padding: 16px 0;
    }
  }
}
</style>
