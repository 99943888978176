<template>
  <div class="carousel">
    <vue-content-loading v-if="loading" :weight="100" :height="167">
      <rect rx="4" ry="4" width="100%" height="167" />
    </vue-content-loading>
    <div :style="loading ? 'height: 0px' : ''" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in dataBanners"
          :key="index"
        >
          <a :href="item.externalUrl || `/item/?id=${item.id}`">
            <img :src="item.image" alt />
          </a>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.min.css";
import Swiper from "swiper";
import VueContentLoading from "vue-content-loading";

export default {
  name: "carousel",
  components: { VueContentLoading },
  props: {},
  data() {
    return {
      loading: true,
      dataBanners: [],
      bannerDataReady: false,
    };
  },

  methods: {
    initSwiper() {
      const self = this;
      const swiper = new Swiper(".swiper-container", {
        speed: 1000,
        effect: "fade",
        loop: true,
        preloadImages: true,
        preventClicks: false,
        preventClicksPropagation: false,
        updateOnImagesReady: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        autoHeight: true,
        pagination: {
          el: ".swiper-pagination",
          loop: true,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
          },
        },
        on: {
          init: function () {
            console.log("initialized. swiper"); // this works
          },
          imagesReady() {
            // when image on swiper loaded

            // console.log("images ready."); // this works now, too!

            // fix issue swiper goto slide second
            try {
              swiper.slideTo(0, 0, false);
            } catch (error) {
              console.log(error);
            }

            // hide skeleton loading
            self.loading = false;
          },
        },
      });
    },
    async fetchBanners() {
      // process.env.VUE_APP_API_URL
      return await fetch(process.env.VUE_APP_API_URL + "/banners", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async initBanner() {
      let body = [];
      try {
        const res = await this.fetchBanners();
        console.log("fetchBanners status:", res.status);

        switch (res.status) {
          case 200:
            body = await res.json();
            this.dataBanners = body.data;
            this.dataBanners.forEach((banner) => {
              if (!banner["externalUrl"]) {
                banner["externalUrl"] = "#";
              }
            });
            break;
          default: {
            console.log("no case:");
            break;
          }
        }
      } catch (error) {
        console.log(error);
      }

      if (this.dataBanners.length == 0) {
        // this.dataBanners.push({
        //   id: 0,
        //   image: require("@/assets/ex_banner1.jpg"),
        //   externalUrl: "#",
        // });
        this.loading=false
      }

      this.$nextTick(() => {
        this.bannerDataReady = true;
      });
    },
  },
  watch: {
    bannerDataReady(val) {
      if (val) {
        this.initSwiper();
      }
    },
  },
  async created() {},
  async mounted() {
    this.initBanner();
    //this.initSwiper();
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.swiper-container {
  width: 100%;
  /* border-radius: 10px; */
}
.swiper-container img {
  width: 100%;
}
</style>

<style>
.swiper-pagination-bullet-active {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.5) !important;
  /* width: 15px !important; */
  border-radius: 20px !important;
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
</style>
