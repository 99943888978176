<template>
  <b-modal id="term_modal" class="pb-0" hide-footer centered>
    <template v-slot:modal-header-close>
      <i class="close-modal icon-cancel-circled-outline"></i>
    </template>
    <div class="d-block">
      <div class="term_content text-center my-0">
        <h5>
          เงื่อนไขและข้อตกลง
          <br />ในการแลกรับของรางวัล
        </h5>
        <p>
          1.ลูกค้าต้องทำการ&nbsp;Add Line&nbsp;@sermsukfamily
          และทำตามขั้นตอนเพื่อลงทะเบียนก่อนโดยใช้ข้อมูล: รหัสร้านค้า,
          เลขที่บัตรประชาชน และเบอร์โทรศัพท์ที่ใช้ในการลงทะเบียน
        </p>
        <p>
          2.เมื่อทำการลงทะเบียนแล้ว ทุกครั้งที่ลูกค้าซื้อสินค้ากับบริษัทฯ
          เลขที่ใบเสร็จ&nbsp;12&nbsp;หลักจะปรากฏ
          และลูกค้าจะต้องนำเลข&nbsp;4&nbsp;หลักสุดท้ายของใบเสร็จ
          ใส่ลงให้ตรงกับเลข&nbsp;12&nbsp;หลักข้างหน้าเพื่อยืนยันการรับคะแนน&nbsp;
        </p>
        <p>
          3.ลูกค้าสามารถตรวจสอบคะแนนได้ในวันถัดไปผ่าน&nbsp;Line @sermsukfamily
          หลังจากยืนยันใบเสร็จแล้ว
        </p>
        <p>
          4.ลูกค้าสามารถเลือก และแลกรับรางวัลด้วยคะแนนที่ปรากฏภายใน&nbsp;Line
          @sermsukfamily เท่านั้น ทางบริษัทฯ จะไม่รับแลกผ่านช่องทางอื่นๆ&nbsp;
        </p>
        <p>
          5.ลูกค้าสามารถแลกรางวัลได้ภายในระยะเวลาที่กำหนด
          โดยลูกค้าสามารถเลือกได้ตามความต้องการเมื่อมีคะแนนสะสมถึงเกณฑ์
        </p>
        <p>
          6.เมื่อมีการยืนยันการแลกรางวัลแล้ว ลูกค้าไม่สามารถเปลี่ยนแปลง
          หรือยกเลิกได้
        </p>
        <p>
          7.บมจ.เสริมสุขขอสงวนสิทธิ์ที่จะไม่นำคะแนนจากนอกระบบ
          หรือด้วยวิธีการอื่นๆ สำหรับลูกค้าที่ไม่ได้มีทำตามวิธีการที่กำหนด
        </p>
        <p>
          8.ในกรณีที่ของแลกบางรายการหมดสต็อก
          หรือเปลี่ยนรุ่นการผลิตโดยผู้ผลิต/ผู้จัดจำหน่าย บมจ.
          เสริมสุข&nbsp;ขอสงวนสิทธิ์ที่จะทดแทนด้วยของแลกที่มีคุณภาพ
          และราคาที่ใกล้เคียงกัน โดยมิต้องแจ้งให้ทราบล่วงหน้า
        </p>
        <p>
          9.บมจ. เสริมสุขขอสงวนสิทธิ์ในการเปลี่ยนแปลงหรือยกเลิก แก้ไข
          เพิ่มเติมรูปแบบ เงื่อนไข หรือองค์ประกอบใด ๆ ของรายการของแลกซื้อ
          และรายละเอียดของรายการ
          &nbsp;ที่ระบุในแคตตาล็อกนี้ตามระยะเวลาที่เห็นสมควร
        </p>
        <p>
          10.กรุณาตรวจเช็คสภาพของแลก ว่าอยู่ในสภาพที่สมบูรณ์ ไม่แตกหัก
          หรือชำรุดเสียหายก่อนการเซ็นรับของทุกครั้ง
        </p>
        <p>
          11.บมจ. เสริมสุขมิได้มีส่วนเกี่ยวข้องในการจำหน่าย หรือให้บริการ
          ในกรณีที่ของแลก ชำรุดเสียหายหรือมีข้อบกพร่องใดๆ
          หลังจากที่ได้ทำการรับมอบไปแล้วนั้น
          ขอให้สมาชิกติดต่อกับทางบริษัทผู้จัดจำหน่ายโดยตรง
          ตามชื่อและหมายเลขโทรศัพท์ที่ผู้จัดจำหน่ายได้แจ้งไว้
        </p>
        <p>
          12.เสริมสุขขอสงวนสิทธิ์ไม่รับคืนหรือเปลี่ยนของแลก หากของที่สมาชิกแลก
          ได้รับการจัดส่ง และเซ็นรับของเป็นที่เรียบร้อยแล้ว
          กรณีของแลกที่มีใบรับประกันคุณภาพ และบริการจากผู้ผลิต/ผู้จัดจำหน่าย
          ให้เป็นไปตามกฎระเบียบของการประกันคุณภาพ
          และบริการจากผู้ผลิตผู้จัดจำหน่ายนั้นๆ โดยบมจ.
          เสริมสุขไม่มีความรับผิดชอบใดๆ ในตัวสินค้าทั้งสิ้น
        </p>
        <p>
          13.ทางบริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขของรายการ
          หรือรางวัลตามความเหมาะสม โดยไม่ต้องแจ้งล่วงหน้า
        </p>
        <b>กติกาสินค้าประจำเดือน</b>
        <p>
          1.สินค้าที่เป็นสินค้าประจำเดือนจะประกาศทุกวันที่&nbsp;1&nbsp;ของเดือน
        </p>
        <p>
          2.สินค้าประจำเดือนจะได้คะแนนพิเศษตามเงื่อนไขที่ประกาศใน Line
          @sermsukfamily
        </p>
      </div>
      <div class="custom-control custom-checkbox text-center">
        <input
          ref="checkTerm"
          type="checkbox"
          class="custom-control-input"
          id="termCkb"
          name="termCkb"
          v-model="checkTerm"
          required
        />
        <label class="custom-control-label" for="termCkb"
          >ฉันยอมรับ เงื่อนไขข้อตกลง</label
        >
      </div>
      <hr class="my-0" />
      <!-- <div class="term_content text-center mt-2">
        <h5 class="text-center">นโยบายความเป็นส่วนตัว</h5>
        <p>
          เพื่อให้สอดคล้องกับกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล บริษัท
          เสริมสุข จำกัด (มหาชน) (“บริษัท”)
          มีความจำเป็นต้องขอความยินยอมในการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของท่านแก่บริษัท และบริษัทในกลุ่มบริษัท
          ไทยเบฟเวอเรจ จำกัด (มหาชน) (“กลุ่มไทยเบฟ”)
          และ/หรือบุคคลที่ได้รับมอบหมายจากบริษัทให้เป็นผู้ประมวลผลข้อมูลส่วนบุคคล
          และ/หรือหน่วยงานของรัฐและ/หรือเอกชนเพื่อปฏิบัติให้เป็นไปตามกฎหมาย
          โดยข้อมูลส่วนบุคคลดังกล่าวได้แก่ข้อมูลดังต่อไปนี้
        </p>
        <p>
          ชื่อ นามสกุล เลขที่ประจำตัวประชาชน ที่อยู่ อีเมล เบอร์โทรศัพท์ ID Line
          ชื่อบัญชี Social Network ต่างๆ รูปถ่าย
          สำเนาบัตรประจำตัวประชาชนหรือสำเนาหนังสือเดินทาง (กรณีต่างด้าว)
          สำเนาใบทะเบียนภาษีมูลค่าเพิ่ม (ภ.พ.20)
          บริษัทมีวัตถุประสงค์ในการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อยืนยันตัวบุคคลที่ได้รับของรางวัล
          การรับ-จ่ายของรางวัล การเข้าร่วมกิจกรรมส่งเสริมการขายและการตลาด
          การจัดกิจกรรมส่งเสริมการขายและการตลาด การเสนอขายสินค้า โฆษณา
          ประชาสัมพันธ์สินค้าและโปรโมชั่นต่างๆ
          ของบริษัทและกลุ่มไทยเบฟโดยมีระยะเวลาในการเก็บรวบรวม
          ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเป็นเวลา 5 ปี
          นับแต่วันที่ร่วมกิจกรรม
          ข้าพเจ้าได้อ่านและรับทราบประกาศว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
          ตามที่ระบุในเอกสารซึ่งแนบมาพร้อมนี้แล้ว
          และตกลงยินยอมให้บริษัทเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าได้ตามระยะเวลาและวัตถุประสงค์ข้างต้น
        </p>
      </div>
      <div class="custom-control custom-checkbox text-center">
        <input
          ref="checkPDPA"
          type="checkbox"
          class="custom-control-input"
          id="pdpaCkb"
          name="pdpaCkb"
          v-model="checkPDPA"
          required
        />
        <label class="custom-control-label" for="pdpaCkb"
          >ฉันยอมรับ นโยบายความเป็นส่วนตัว</label
        >
      </div> -->
    </div>
    <div class="text-center">
      <button
        :disabled="!checkTerm"
        id="okBtn"
        class="btn btn-primary mt-1"
        @click="confirm()"
        data-tag="term_confirm"
      >
        ยอมรับ
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      checkTerm: false,
      // checkPDPA: false,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.term_content {
  overflow: scroll;
  min-height: 100px;
  height: 35vh;
  font-size: 0.9em;
  line-height: 1.5em;
}

#okBtn {
  font-size: 16px;
  width: 80%;
  padding: 5px 0;
}
</style>