<template>
  <div class="redeems root">
    <div v-if="activeList.length > 0">
      <div
        class="redeem-logo-container"
        data-testid="redeem-logo"
        v-for="(item, i) in activeList"
        :key="`logo-${i}`"
      >
        <img
          v-lazy="item.listBannerImageUrl"
          width="100%"
          height="auto"
        />        
        <b-button variant="primary" pill @click="joinRedeem()" data-tag="join"
          >ร่วมลุ้นรางวัล คลิกที่นี่</b-button
        >
      </div>
    </div>
    <div v-else>
      <div class="redeem-logo-container" data-testid="redeem-logo">
        <img
          v-lazy="require('@/assets/luckydraw/Lucky_Sep_2024.jpg')"
          width="100%"
          height="auto"
        />
      </div>
    </div>
    <!-- <RedeemList v-if="false && comingList.length>0 || activeList.length > 0" data-testid="redeem-list" /> -->
    <div
      v-if="inactiveList.length > 0"
      class="redeem-announcement-container container"
      data-testid="redeem-announcement"
    >
      <div class="title">ประกาศรายชื่อผู้โชคดี</div>
      <div v-for="(item, i) in inactiveList"
        :key="`announce-${i}`"
        class="announce"
        @click="viewAnnounce(item.id)">
        <img src="@/assets/luckydraw/Announce_Sep_2024.jpg" />
      </div>
      <!-- <b-button
        v-for="(item, i) in inactiveList"
        :key="`button-${i}`"
        variant="primary"
        block
        @click="viewAnnounce(item.id)"
        :data-tag="'announce_' + item.id"
      >
        <img v-lazy="item.listBannerImageUrl" width="150px" height="100px" />
        <div>ผู้โชคดี {{ item.title }}</div>
      </b-button> -->
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { BButton } from "bootstrap-vue";
// import RedeemList from "@/components/redeemList.vue";

export default {
  name: "redeems",
  components: { "b-button": BButton },
  data() {
    return {
      activeList: [],
      comingList: [],
      inactiveList: [],
    };
  },
  computed: {
    redeemsData() {
      return this.$store.state.redeems;
    },
  },
  methods: {
    async fetch() {
      // process.env.VUE_APP_API_URL
      return await fetch(process.env.VUE_APP_API_URL + "/lucky_draws", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async init() {
      let body = [];
      try {
        const res = await this.fetch();
        switch (res.status) {
          case 200:
            body = await res.json();
            this.$store.commit("SET_REDEEMS", body);
            this.activeList = this.redeemsData.filter((item) => {
              return item.isActive === true;
            });
            this.comingList = this.redeemsData.filter((item) => {
              return  (item.startAt!=null && new Date(item.startAt).getTime() > new Date()) || (item.announceAt!=null && new Date(item.announceAt).getTime() > new Date());
            });
            this.inactiveList = this.redeemsData.filter((item) => {
              return item.isActive === false && !(item.announceAt==null || new Date(item.announceAt).getTime() >= new Date());
            });

            console.log({
              in:this.inactiveList
            })
            return;
          default: {
            throw "no case status";
          }
        }
      } catch (error) {
        this.$store.commit("SET_REDEEMS_STATUS", "error");
      }
    },
    joinRedeem() {
      const redeemData = this.activeList;

      if (redeemData.length > 0) {
        this.$router.push({
          name: "lucky-draws-detail",
          params: { data: redeemData[0], lucky_draws_id: redeemData[0].id},
        });
        return true;
      }
      return false;
    },
    viewAnnounce(id) {
      const data = this.inactiveList.filter((item) => {
        return item.id == id;
      });

      this.$router.push({
        name: "lucky-draws-announcement",
        params: { data: data[0] },
      });
      return true;
    },
  },
  mounted() {
    this.init();
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
$light-gray: #eaf2ff;
$light-blue: #0ac3ff;

.root {
  background: $light-gray;
  font-size: 16px;
  // margin-top: 4px;
  .redeem-logo-container {
    text-align: center;
    // position: relative;
    button {
      // position: absolute;
      // left: 50%;
      // bottom: 30px;
      // transform: translate(-50%, 0);
      // width: 210px;
      margin: 16px 0;
    }
  }
  .redeem-announcement-container {
    padding-bottom: 20px;
    .title {
      font-size: 1.2em;
      font-weight: bold;
      padding-bottom: 8px;
    }
    button {
      background: $light-blue;
      border-color: $light-blue;
      height: 130px;
      display: flex;
      img,
      div {
        margin: auto;
      }
      div {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        font-size: 16px;
        font-weight: bold;
        width: 50%;
        // margin-left: 45px;
      }
    }
  }

  .announce {
    border: 1px solid #ced3da;
    cursor: pointer;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 8px;
    overflow: hidden;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.15);
    &:hover img {
      opacity: 0.7;
    }
    &:active img {
      opacity: 0.5;
    }
    img {
      z-index: -10;
      width: 100%;
      object-fit: cover;
    }
    .mycard-body {
      background: #fff;
      height: 110px;
      padding: 5px;
      font-size: 0.8em;
      .rd_txt_title {
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
</style>
