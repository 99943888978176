<template>
  <div class="pinCodeInput">
    <div id="pincode-input-overlay"></div>
    <div class="container-enter-pin">
      <div class="enter-pin">
        <div class="head">
          <div v-if="reset_pin" class="reset-pin" @click="$emit('reset-pin', true)">{{reset_pin}}</div>
        </div>
        <div class="close" @click="$emit('close', true)">
          <svg
            viewPort="0 0 12 12"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="11"
              x2="11"
              y2="1"
              stroke="black"
              stroke-width="2"
              vector-effect="non-scaling-stroke"
            />
            <line
              x1="1"
              y1="1"
              x2="11"
              y2="11"
              stroke="black"
              stroke-width="2"
              vector-effect="non-scaling-stroke"
            />
          </svg>
        </div>
        <div class="form-group text-center">
          <div class="title">{{title}}</div>
          <div class="pindot">
            <span class="dot" v-for="index in pin_length" :key="'dot_'+index"></span>
          </div>
          <input type="tel" id="pinInput" inputmode="numeric" hidden />
        </div>
      </div>
      <div class="keypad">
        <div class="pad">1</div>
        <div class="pad">2</div>
        <div class="pad">3</div>
        <div class="nl"></div>
        <div class="pad">4</div>
        <div class="pad">5</div>
        <div class="pad">6</div>
        <div class="nl"></div>
        <div class="pad">7</div>
        <div class="pad">8</div>
        <div class="pad">9</div>
        <div class="nl"></div>
        <div class="pad" data-fn="clear">C</div>
        <div class="pad">0</div>
        <div class="pad del" data-fn="del">
          <svg
            viewBox="0 0 25 25"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g fill="none" transform="translate(0, 0)">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M22,3 L7,3 C6.31,3 5.77,3.35 5.41,3.88 L0.37,11.45 C0.15,11.79 0.15,12.22 0.37,12.56 L5.41,20.12 C5.77,20.64 6.31,21 7,21 L22,21 C23.1,21 24,20.1 24,19 L24,5 C24,3.9 23.1,3 22,3 Z M18.3,16.3 C17.91,16.69 17.28,16.69 16.89,16.3 L14,13.41 L11.11,16.3 C10.72,16.69 10.09,16.69 9.7,16.3 C9.31,15.91 9.31,15.28 9.7,14.89 L12.59,12 L9.7,9.11 C9.31,8.72 9.31,8.09 9.7,7.7 C10.09,7.31 10.72,7.31 11.11,7.7 L14,10.59 L16.89,7.7 C17.28,7.31 17.91,7.31 18.3,7.7 C18.69,8.09 18.69,8.72 18.3,9.11 L15.41,12 L18.3,14.89 C18.68,15.27 18.68,15.91 18.3,16.3 Z"
                fill="#1D1D1D"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pinCodeInput",
  props: {
    title: {
      type: String,
      default: "",
    },
    reset_pin: {
      type: String,
      default: "",
    },
    pin_length: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // document.body
    //   .appendChild(``);
    const input = document.getElementById("pinInput");

    input.addEventListener("change", () => {
      const dot = document.querySelectorAll(".pindot .dot");
      dot.forEach((e) => {
        e.classList.remove("active");
      });
      for (let i = 0; i < input.value.length; i++) {
        dot[i].classList.add("active");
      }
    });
    document.querySelectorAll(".keypad .pad").forEach((e) => {
      // add style on press
      e.ontouchstart = () => {
        e.classList.add("click");
      };
      e.ontouchend = () => {
        e.classList.remove("click");
      };
      e.ontouchcancel = () => {
        e.classList.remove("click");
      };

      // when clicked
      e.onclick = () => {
        switch (e.dataset.fn) {
          case "del":
            input.value = input.value.slice(0, -1);
            break;
          case "clear":
            input.value = "";
            break;
          default:
            if (input.value.length < this.pin_length) {
              input.value += e.innerText;
            }
        }
        window.callEvent(input, "change");

        if (input.value.length == this.pin_length) {
          setTimeout(() => {
            this.$emit("already-pin", input.value);
          }, 100);
        }
      };
    });
  },
};
</script>

<style scoped>
.pinCodeInput {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Opera and Firefox */
}

#pincode-input-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(142, 142, 142, 0.74);
}

.container-enter-pin {
  z-index: 1055;
  border-top: 1px solid rgb(233, 233, 233);
  padding: 10px 10px 40px 10px;
  position: fixed;
  background: #fff;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  max-width: 450px;
}

.head {
  width: 100%;
}

.reset-pin {
  display: inline-block;
  color: #2599d6;
  font-size: 20px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}

.title {
  font-size: 26px;
}

.pindot {
  margin: 20px 0 50px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dot {
  margin: 0 8px;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 100%;
}

.dot.active {
  background: #444;
}

.keypad {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  color: #444;
  justify-items: center;
  align-items: center;
  justify-content: space-around;
}

.keypad .nl {
  width: 100%;
}

.keypad .pad:focus {
  outline: none;
}

.keypad .pad.click {
  background: #cfcfcf;
}

.keypad .pad {
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 5px 0;
  text-align: center;
  color: rgb(46, 46, 46);
  font-size: 20px;
  background: rgb(247, 247, 247);
  border: 1px solid transparent;
  border-radius: 100%;
  line-height: 58px;
}

.pad.del svg {
  width: 28px;
}
</style>
<style>
</style>
